import React from 'react';
import styles from './Menu.module.scss';
import { useMenu } from "../../../contexts/MenuContext";
import { usePage } from "../../../contexts/PageContext";
import { useUser } from '../../../contexts/UserContext';

const Menu: React.FC = () => {
  const { menuItems } = useMenu();
  const { navigate } = usePage();
  const { user } = useUser();
  const permissions = user?.permissions || {};

  const handleLinkClick = (newPage: string, url: string = ''): void => {
    if (url !== '') {
      window.open(url, '_blank')?.focus();
      return;
    }
    navigate(newPage);
  };

  const hasPermission = (userRight: string | undefined): boolean => {
    if (!userRight) return true; // No permission required
    return Object.keys(permissions).includes(userRight);
  };

  return (
    <div className={`${styles.verticalMenu} row py-2 px-0 m-0`}>
      {menuItems
        .filter((item) => hasPermission(item.userRight)) // Filter items based on userRight
        .map((item, index) => (
          <p key={index}>
            <i className={`${styles.menuIcon} ${item.icon}`}></i>
            <span className={`${styles.menuHeader}`}> {item.header}</span>
            <br />
            {item.links
              .filter((link) => hasPermission(link.userRight)) // Filter links based on userRight
              .map((link, linkIndex) => (
                <a
                  className={`${styles.menuLink}`}
                  href="#"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                    e.preventDefault();
                    handleLinkClick(link.action, link.url ?? '');
                  }}
                  key={linkIndex}
                >
                  <span className="px-1">{link.name}</span>
                  <br />
                </a>
              ))}
          </p>
        ))}
    </div>
  );
};

export default Menu;