import React from 'react';
import './FormButton.scss';

interface FormButtonProps {
  label: string; // The text to display on the button
  onClick: () => void; // The click handler function for the button
  columnClass?: string; // Optional column class for the outer div
  buttonClass?: string; // Optional button class
  disabled?: boolean; // Optional disabled state for the button
}

const FormButton: React.FC<FormButtonProps> = ({
  label,
  onClick,
  columnClass = 'col-md-2', // Provide a default column class
  buttonClass = 'btn-primary', // Provide a default button class
  disabled = false, // Default is not disabled
}) => {
  return (
    <div className={`${columnClass} mb-2 d-flex flex-column justify-content-end`}>
      <input
        type="button"
        onClick={onClick} // Attach the provided onClick handler
        className={`btn btn-sm w-100 ${buttonClass}`} // Bootstrap classes for styling
        value={label} // The button label
        disabled={disabled} // Apply the disabled attribute based on the prop
      />
    </div>
  );
};

export default FormButton;