import { Contract, ContractDetails, Workflow, PromptGroup, Prompt, MandatoryPrompt } from './ContractInterfaces';

export class ContractClass implements Contract {
    contractId: string;
    contractName: string;
    createdAt: string;
    contractDetails: ContractDetails;
    vesselRelated: boolean;

    constructor(data?: Partial<Contract>) {
        this.contractId = data?.contractId ?? '';
        this.contractName = data?.contractName ?? '';
        this.createdAt = data?.createdAt ?? '2024-01-01';
        this.contractDetails = this.initializeContractDetails(data?.contractDetails);
        this.vesselRelated = data?.vesselRelated ?? false;
    }

    private initializeContractDetails(details?: Partial<ContractDetails>): ContractDetails {
        return {
            workflows: details?.workflows?.map(workflow => this.initializeWorkflow(workflow)) ?? [],
        };
    }

    private initializeWorkflow(workflow: Partial<Workflow>): Workflow {
        return {
            workflowId: workflow.workflowId ?? '',
            workflowName: workflow.workflowName ?? '',
            promptGroups: workflow.promptGroups?.map(group => this.initializePromptGroup(group)) ?? [],
        };
    }

    private initializePromptGroup(promptGroup: Partial<PromptGroup>): PromptGroup {
        return {
            maxResponsesToShow: promptGroup.maxResponsesToShow ?? 0,
            prompts: promptGroup.prompts?.map(prompt => this.initializePrompt(prompt)) ?? [],
            mandatoryPrompts: promptGroup.mandatoryPrompts?.map(mandatory => this.initializeMandatoryPrompt(mandatory)) ?? [],
            promptGroupId: promptGroup.promptGroupId ?? '',
            promptGroupName: promptGroup.promptGroupName ?? '',
            promptGroupDescription: promptGroup.promptGroupDescription ?? ''
        };
    }

    private initializeMandatoryPrompt(mandatory: Partial<MandatoryPrompt>): MandatoryPrompt {
        return {
            mandatoryPromptId: mandatory.mandatoryPromptId ?? '',
            mandatoryPromptName: mandatory.mandatoryPromptName ?? '',
            expectedResult: mandatory.expectedResult ?? false,
            promptAdvice: mandatory.promptAdvice ?? '',
            prompt: mandatory.prompt ?? '',
            promptImportance: mandatory.promptImportance ?? 'NEUTRAL'
        };

    }

    private initializePrompt(prompt: Partial<Prompt>): Prompt {
        return {
            promptId: prompt.promptId ?? '',
            promptName: prompt.promptName ?? '',
            prompt: prompt.prompt ?? '',
            promptType: prompt.promptType ?? '',
            promptImportance: prompt.promptImportance ?? '',
            enableCitation: prompt.enableCitation ?? false,
            promptAdvice: prompt.promptAdvice ?? ''
        };
    }

}