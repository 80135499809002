export const defaultMenuItems = [
    {
      header: "NAVILEX AI",
      icon: "bi bi-journal-bookmark-fill",
      links: [
        { name: "-NEW CONTRACT", action: "new-contract" },
        { name: "-CONTRACT HISTORY", action: "workflows" },
      ],
    },
    {
      userRight:"navilex-admin",
      header: "ADMIN",
      icon: "bi bi-gear",
      links: [
        //{ name: "-CONTRACT TYPES", action: "contract-admin",userRight:"navilex-admin" },
        { name: "-TOKEN", action: "token",userRight:"navilex-admin-token" },
      ],
    },{
      header: "CLAUSE LIBRARY",
      icon: "bi bi-book",
      links: [
        { name: "-TC IN", action: "link", url:"https://wbulk.sharepoint.com/sites/FILES-WESTERNBULK/_layouts/15/Doc.aspx?sourcedoc={65a966fe-f5c7-4fcf-bdc7-34afd02ee705}&action=edit&wd=target%28Clause%20Library.one%7Cadeb3a2d-10cc-468c-b646-699cac37d33b%2FCLAUSE%20LIBRARY%7Cb77fa697-f561-4b63-83df-2b214d09f45f%2F%29&wdorigin=703" },
        { name: "-TC OUT", action: "link", url:"https://wbulk.sharepoint.com/sites/FILES-WESTERNBULK/_layouts/15/Doc.aspx?sourcedoc={65a966fe-f5c7-4fcf-bdc7-34afd02ee705}&action=edit&wd=target%28Clause%20Library.one%7Cadeb3a2d-10cc-468c-b646-699cac37d33b%2FCLAUSE%20LIBRARY%7Cb77fa697-f561-4b63-83df-2b214d09f45f%2F%29&wdorigin=703" },
        { name: "-CARGO", action: "link",url:"https://wbulk.sharepoint.com/sites/FILES-WESTERNBULK/_layouts/15/Doc.aspx?sourcedoc={65a966fe-f5c7-4fcf-bdc7-34afd02ee705}&action=edit&wd=target%28Clause%20Library.one%7Cadeb3a2d-10cc-468c-b646-699cac37d33b%2FCLAUSE%20LIBRARY%7Cb77fa697-f561-4b63-83df-2b214d09f45f%2F%29&wdorigin=703" },
      ],
    },{
      header: "WESTERN AVA",
      icon: "bi bi-robot",
      links: [
        { name: "Ava", action: "link",  url:"https://westernava.com" },
        // Additional links
      ],
    }
    // Add more sections as needed
  ];
  
  
  