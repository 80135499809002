import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { User,UserRole } from '../models/UserInterface';
import { useAuth } from './AuthContext';

interface UserContextProps {
  user: User | null;
  setUser: (user: User | null) => void;
  getUserId: () => string | null; // Add getUserId method
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

const defaultUser: User = {
  name: '',
  email: '',
  permissions: {},
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const { userData } = useAuth(); // Fetch userData from AuthContext directly
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (userData) {
      setUser({ ...defaultUser, ...userData });
    }
  }, [userData]);

  const getUserId = (): string | null => {
    return user?.email ? user.email.split('@')[0].toLowerCase() : 'unknown';
  };



  return (
    <UserContext.Provider value={{ user, setUser,getUserId }}>
      {children}
    </UserContext.Provider>
  );
};