import React from 'react';
import './JobStatus.scss';

interface StatusComponentProps {
    status: string;
    progress: number;
}

const JobStatus: React.FC<StatusComponentProps> = ({ status, progress }) => {
    return (
        <div className="status-component">
            <label className="form-label fw-semibold">STATUS: {status}</label>
            <div className="progress" role="progressbar" title={`${progress}%`}>
                <div className="progress-bar bg-info" style={{ width: `${progress}%` }}>{progress}%</div>
            </div>
        </div>
    );
};

export default JobStatus;
