import React from 'react';
import { ApiClientProvider } from './contexts/ApiContext';
import { PageProvider } from './contexts/PageContext';
import {MenuProvider}from './contexts/MenuContext';
import Footer from './components/footer/Footer';
import MainContent from './components/mainContent/MainContent';
import NavBar from './components/navigation/NavBar/NavBar';
import { useAuth } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import './styles/App.scss'; 

const App: React.FC = () => {
  const { token, loading, error } = useAuth();
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center full-vh-height">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="indexpage">{error}</div>; // Display the error message
  }

  if (!token) {
    return <div className="indexpage">Not authenticated. Please refresh and login.</div>;
  }

  return (
    <UserProvider>
      <ApiClientProvider>
        <PageProvider>
          <MenuProvider>
            <NavBar />
            <MainContent/>
          </MenuProvider>
          <Footer />
        </PageProvider>
      </ApiClientProvider>
    </UserProvider>
  );
};

export default App;
