// src/components/NavBar/NavBar.tsx
import React from 'react';
import styles from './NavBar.module.scss';
import logo from '../../../assets/images/westernbulk_logo_white.png';
import { useMenu } from "../../../contexts/MenuContext";


const NavBar: React.FC = () => {
  const { toggleVisibility } = useMenu();

  return (
    <div className="container-fluid p-0 m-0">
      <nav className="navbar navbar-dark bg-dark sticky-sm-top px-3">
        <div className="d-flex justify-content-start">
          <button
            onClick={toggleVisibility}
            className="btn p-0" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#vertical_menu" 
            aria-expanded="true" 
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="d-flex justify-content-end">
            <img src={logo} alt="Logo" className={`${styles.img} justify-content-end p-0 m-0`}/>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
