import { ApiClient } from './apiClient';

interface FetchResponseDocumentLinks {
    data: {
        pdfDocumentLinks: Array<{ [name: string]: string }>;
        strDocumentLinks: Array<{ [name: string]: string }>;
    };
}

export class ApiDocuments {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    async fetchDocument(userId: string, jobId: string): Promise<Array<{ name: string; url: string }>> {
        try {
            const payload = JSON.stringify({ userId, jobId });
            const response = await this.apiClient.post<FetchResponseDocumentLinks>(
                'genai/fetchData?dataType=documentLinks',
                payload
            );

            const documentLinks: Array<{ name: string; url: string }> = [];

            const data = response.data;

            if (data.pdfDocumentLinks) {
                data.pdfDocumentLinks.forEach((doc) => {
                    for (const name in doc) {
                        if (doc.hasOwnProperty(name)) {
                            documentLinks.push({ name, url: doc[name] });
                        }
                    }
                });
            }

            if (data.strDocumentLinks) {
                data.strDocumentLinks.forEach((doc) => {
                    for (const name in doc) {
                        if (doc.hasOwnProperty(name)) {
                            documentLinks.push({ name:"Combined documents (raw text).txt", url: doc[name] });
                        }
                    }
                });
            }

            return documentLinks;
        } catch (error) {
            throw new Error('Failed to fetch document links');
        }
    }
}