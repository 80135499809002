import React from 'react';

interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  id: string;
  label: string;
  options: SelectOption[];
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  columnClass?: string;
  name?: string;
  disabled?: boolean;
}

const SelectDropdown: React.FC<SelectProps> = ({
  id,
  label,
  options,
  value,
  onChange,
  columnClass = "col-md-2", // Default column size
  name="",
  disabled=false
}) => {
  return (
    <div className={`${columnClass} mb-2`}>
      <label htmlFor={id} className="form-label fw-semibold">{label}</label>
      <select
        id={id}
        name={name} // Make sure to apply the name prop to the select element
        className="selectpicker form-control form-control-sm"
        value={value}
        onChange={onChange} // Pass the entire event object directly to the onChange handler
        data-live-search="true"
        disabled={disabled}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
};


export default SelectDropdown;
