import React, { useState, useEffect, useRef } from 'react';

// Define props for the component
interface DropdownSearchProps {
    id: string; // Unique ID for the input element
    label: string; // Label text for the dropdown
    options: string[]; // List of dropdown options
    placeholder?: string; // Placeholder text for the input field
    maxSuggestions?: number; // Maximum number of suggestions to display
    onSelect: (selectedOption: string) => void; // Callback when an option is selected
    columnClass?: string; // Additional CSS classes for layout
    disabled?: boolean; // Whether the dropdown is disabled
    value?: string; // Optional initial value
}

const DropdownSearch: React.FC<DropdownSearchProps> = ({
    id,
    label,
    options,
    placeholder = 'Type to search...', // Default placeholder
    maxSuggestions = 10, // Default maximum suggestions
    onSelect,
    columnClass = 'col-md-2', // Default layout class
    disabled = false, // Default is enabled
    value = '', // Default value is an empty string
}) => {
    // State variables
    const [inputValue, setInputValue] = useState<string>(value); // Initialize with value prop
    const [filteredOptions, setFilteredOptions] = useState<string[]>([]); // Filtered options based on input
    const [focusedIndex, setFocusedIndex] = useState<number>(-1); // Focused dropdown index
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false); // Dropdown visibility

    // Flag to block dropdown updates after selection
    const skipUpdateAfterSelect = useRef<boolean>(false);

    // Update inputValue when value prop changes
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    // Effect to handle filtered options and dropdown visibility
    useEffect(() => {
        if (skipUpdateAfterSelect.current) {
            // Reset the flag and skip this effect
            skipUpdateAfterSelect.current = false;
            return;
        }

        if (inputValue) {
            const matches = options
                .filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()))
                .slice(0, maxSuggestions);

            // If inputValue matches the only item in matches, clear the list and hide the dropdown
            if (matches.length === 1 && matches[0].toLowerCase() === inputValue.toLowerCase()) {
                setFilteredOptions([]);
                setIsDropdownVisible(false); // Hide dropdown
            } else {
                setFilteredOptions(matches);
                setIsDropdownVisible(matches.length > 0); // Show dropdown if matches exist
            }
        } else {
            setFilteredOptions([]);
            setIsDropdownVisible(false); // Hide dropdown if input is empty
        }
        setFocusedIndex(-1); // Reset focus index when input changes
    }, [inputValue, options, maxSuggestions]);

    // Handle input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
    };

    // Handle option selection
    const handleOptionSelect = (option: string): void => {
        skipUpdateAfterSelect.current = true; // Set the flag to skip the next update
        setInputValue(option); // Update input value
        setFilteredOptions([]); // Clear suggestions
        setIsDropdownVisible(false); // Hide dropdown
        setFocusedIndex(-1); // Reset focus index
        onSelect(option); // Call the parent callback
    };

    // Handle keyboard navigation
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setFocusedIndex((prev) => Math.min(prev + 1, filteredOptions.length - 1));
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setFocusedIndex((prev) => Math.max(prev - 1, 0));
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (filteredOptions.length > 0) {
                const optionToSelect =
                    focusedIndex >= 0 ? filteredOptions[focusedIndex] : filteredOptions[0];
                handleOptionSelect(optionToSelect);
            }
        }
    };

    return (
        <div className={`${columnClass} mb-2`} style={{ position: 'relative' }}>
            <label htmlFor={id} className="form-label fw-semibold">
                {label}
            </label>
            <input
                id={id}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                className="form-control form-control-sm"
                disabled={disabled}
            />
            {isDropdownVisible && (
                <ul
                    className="dropdown-menu show w-100"
                    style={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        fontSize: 'smaller',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {filteredOptions.map((option, index) => (
                        <li
                            key={option}
                            className={`dropdown-item ${index === focusedIndex ? 'active' : ''}`}
                            style={{ cursor: 'pointer' }}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleOptionSelect(option);
                            }}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DropdownSearch;