import React from 'react';
import PageHeader from "../../components/common/PageHeader/PageHeader";


const DefaultContent: React.FC = () => {
  return (
    <>
    <PageHeader title='Welcome to NaviLex'></PageHeader>
    <div className="row px-4 m-0">
      NaviLex helps you read Maritime contracts!
    </div>
    </>
  );
};

export default DefaultContent;