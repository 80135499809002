import React, { createContext, useContext, useState, ReactNode } from 'react';
import { defaultMenuItems } from '../config/defaultMenuItems'; // Adjust as needed

interface Link {
  name: string;
  action: string;
  url?: string; // Add this line
  userRight?: string; // Add this line
}

interface MenuItem {
  header: string;
  links: Link[];
  icon: string;
  userRight?: string; // Add this line
}

interface MenuContextType {
  menuItems: MenuItem[];
  isVisible: boolean; // State to track the visibility of the menu
  toggleVisibility: () => void; // Function to toggle the menu visibility
}

const MenuContext = createContext<{
  menuItems: MenuItem[];
  isVisible: boolean;
  toggleVisibility: () => void;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>; 
} | undefined>(undefined);

// Assuming you decide to expose setIsVisible for more direct control
export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>(defaultMenuItems);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const toggleVisibility = () => setIsVisible(!isVisible);

  // Including setIsVisible in the context value
  return (
    <MenuContext.Provider value={{ menuItems, isVisible, toggleVisibility, setIsVisible }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};
