// PageProvider.tsx
import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useAuth } from '../contexts/AuthContext';

// Interface for the context
interface PageContextType {
  page: string;
  params: { [key: string]: string | undefined };
  navigate: (newPage: string, newParams?: { [key: string]: string }) => void;
  getParam: (name: string) => string | undefined;
  updateParams: (addParams?: { [key: string]: string }, removeParams?: string[]) => void;
}
interface PageProviderProps {
  children: ReactNode;
}
// Default context value
const defaultContextValue: PageContextType = {
  page: '',
  params: {},
  navigate: () => {},
  getParam: () => undefined,
  updateParams: () => {},
};
// Create the context
const PageContext = createContext<PageContextType>(defaultContextValue);

// Provider component
export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
  const { stateParams } = useAuth();

  // Initialize page and params without any parameters (default domain)
  const [page, setPage] = useState<string>('defaultContent');
  const [params, setParams] = useState<{ [key: string]: string | undefined }>({});

  // Helper function to create a query string from params, filtering out undefined values
  const createQueryString = (params: { [key: string]: string | undefined }) => {
    return new URLSearchParams(
      Object.entries(params).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {} as { [key: string]: string })
    ).toString();
  };
  // Function to navigate to a new page
  const navigate = useCallback((newPage: string, newParams: { [key: string]: string } = {}) => {
    setPage(newPage);
    // append newpage as page= in the url:
    newParams['page'] = newPage;
    setParams(newParams);
    
    const queryString = createQueryString(newParams);
    window.history.pushState({}, '', `?page=${newPage}${queryString ? `&${queryString}` : ''}`);
  }, [params]);
  // Function to get a parameter from the params object
  const getParam = useCallback((name: string): string | undefined => params[name], [params]);
  // Function to update the params object
  const updateParams = useCallback(
    (addParams: { [key: string]: string } = {}, removeParams: string[] = []) => {
      setParams(prevParams => {
        // Clone the previous parameters
        const newParams = { ...prevParams };
        // Remove specified parameters
        removeParams.forEach(name => {
          delete newParams[name];
        });
  
        // Add or update specified parameters
        Object.entries(addParams).forEach(([key, value]) => {
          newParams[key] = value;
        });
  
        const queryString = createQueryString(newParams);
        window.history.replaceState({}, '', `?${queryString}`);
        return newParams;
      });
    },
    [] // No dependencies needed because we're using functional updates
  );

  // Function to apply stateParams if they exist
  const applyStateParams = useCallback(() => {
    if (stateParams) {
      const parsedParams: { [key: string]: string | undefined } = {};
      const searchParams = new URLSearchParams(stateParams);
      searchParams.forEach((value, key) => {
        parsedParams[key] = value;
      });
      setPage(parsedParams['page'] || 'defaultContent');
      setParams(parsedParams);
      const queryString = createQueryString(parsedParams);
      window.history.replaceState({}, '', `?${queryString}`);
    }else{
      // Remove tokens from url
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, [stateParams]);

  // Apply stateParams on mount
  useEffect(() => {
    applyStateParams(); 
    
    const handlePopState = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const newParams: { [key: string]: string | undefined } = {};
      searchParams.forEach((value, key) => {
        newParams[key] = value;
      });
      setPage(newParams['page'] || 'defaultContent');
      setParams(newParams);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [applyStateParams]);

  return (
    <PageContext.Provider value={{ page, params, navigate, getParam, updateParams }}>
      {children}
    </PageContext.Provider>
  );
};

export const usePage = (): PageContextType => useContext(PageContext);