import React from 'react';
import "./DocumentLinks.scss";

// Props for the DocumentLinks component
interface DocumentLinksProps {
    label: string; // Label text above the list of links
    links: { name: string; url: string }[]; // Updated to match the new API response
  }

// DocumentLinks component
const DocumentLinks: React.FC<DocumentLinksProps> = ({ label, links }) => {
    return (
      <div className="document-links">
        <label className="form-label">{label}</label>
        <ul className="list-unstyled">
          {links.map((link, index) => (
            <li key={index}>
              <a 
                href={link.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="document-link"
              >
                {link.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default DocumentLinks;