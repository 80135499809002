import { ApiClient } from './apiClient';
import { v4 as uuidv4 } from 'uuid';
import { ApiChatMessage, Message,MessageType } from '../models/ChatInterfaces';


interface FetchResponseChat {
    data: ApiChatMessage;
}

export class ApiChat {
    private apiClient: ApiClient;
    private jobId: string;
    private userId: string;
    private sessionId: string;

    constructor(apiClient: ApiClient, jobId: string, userId: string) {
        this.apiClient = apiClient;
        this.jobId = jobId;
        this.userId = userId;
        this.sessionId = uuidv4(); // Ensure unique session IDs for each instance
    }

    public async askQuestion(question: string): Promise<Message> {
        const subUrl = 'genai/generateResponses';
        const payload = {
            processingType: "chat",
            userId: this.userId,
            jobId: this.jobId,
            conversationId: this.sessionId,
            contractMetadata: {}, // Include any necessary contract metadata
            humanInput: question
        };
    
        try {
            const response = await this.apiClient.post<FetchResponseChat>(subUrl, JSON.stringify(payload));
            const apiChatMessage: ApiChatMessage = response.data; // Assume the response is directly the ApiChatMessage
            return this.transformApiChatMessage(apiChatMessage); // This now returns only the answer
        } catch (error) {
            console.error('Failed to post question:', error);
            throw new Error(`API call failed: ${error}`);
        }
    }

    private transformApiChatMessage(apiMessage: ApiChatMessage): Message {
        let answer = '';
        if (apiMessage.answer === "NA") {
            answer = "Sorry, I can't find the answer. Try rephrasing.";
        } else if (typeof apiMessage.answer === 'string') {
            answer = apiMessage.answer.replace('</answer>', '');
        } else {
            // Handle the case where apiMessage.answer is not a string
            answer ="Not correct format - please see console log.";
            console.log('answer:', apiMessage.answer);
        }
        
        // Assuming the ApiChatMessage always contains an answer
        let message: Message = {
            text: answer,
            type: MessageType.Answer, // Correctly reference the enum here
            created: apiMessage.created
        };
        return message;
    }
}
