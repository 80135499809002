import {Job,JobMeta,ContractMetadata,ProcessedWorkflow} from './JobInterface';  // Importing the Job interface

// Create the job class:
export class JobClass implements Job {
    jobId: string = '';
    jobMetadata?: JobMeta = {
        jobName: '',
        jobComments: ''
    };
    userId: string = '';
    contractMetadata: ContractMetadata = {
        contractName: '',
        contractId: '',
        createdAt: ''
    };
    status?: string = '';
    processedWorkflows?: ProcessedWorkflow[] = [];
    workflowId?: string = '';
    conversationId?: string = '';
    createdOrUpdatedTimestamp?: string = '';
    contractRelations: Record<string, string> = {}; // New element added
    
    constructor(data?: Partial<Job>) {
        if (data) {
            this.jobId = data.jobId ?? this.jobId;
            this.jobMetadata = data.jobMetadata ?? this.jobMetadata;
            this.userId = data.userId ?? this.userId;
            this.contractMetadata = data.contractMetadata ?? this.contractMetadata;
            this.status = data.status ?? this.status;
            this.processedWorkflows = data.processedWorkflows ?? this.processedWorkflows;
            this.workflowId = data.workflowId ?? this.workflowId;
            this.conversationId = data.conversationId ?? this.conversationId;
            this.createdOrUpdatedTimestamp = data.createdOrUpdatedTimestamp ?? this.createdOrUpdatedTimestamp;
            this.contractRelations = data.contractRelations ?? this.contractRelations;
        }
    }

}