// PageRow.tsx
import React, { ReactNode } from 'react';

interface PageRowProps {
  children: ReactNode; // This allows any valid React node to be passed as children
  className?: string; // Add a className prop to allow for customization. 
}

const PageRow: React.FC<PageRowProps> = ({ children, className= '' }) => {
  return <div className={`${className} row`}>{children}</div>;
};

export default PageRow;
