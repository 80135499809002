// src/api/ApiClient.ts
export class ApiClient {
    private token: string;
    private baseUrl: string;

    constructor(token: string, baseUrl:string) {
        this.token = token;
        this.baseUrl = baseUrl;
    }

    public updateToken(token: string) {
        this.token = token;
    }

    // Flexible GET Request Method with full URL
    async get<T>(subUrl: string, body?: any, headers?: HeadersInit): Promise<T> {
        return this.request<T>(this.baseUrl+subUrl, 'GET', body, headers);
    }

    // POST Request Method with full URL
    async post<T>(subUrl: string, body?: any, headers?: HeadersInit): Promise<T> {
        return this.request<T>(this.baseUrl+subUrl, 'POST', body, headers);
    }

    // PUT Request Method with full URL
    async put<T>(subUrl: string, body?: any, headers?: HeadersInit): Promise<T> {
        return this.request<T>(this.baseUrl+subUrl, 'PUT', body,headers);
    }

    async putFile<T>(fullUrl: string, file: Blob, contentType?: string): Promise<boolean> {
        const headers = new Headers();
        if (contentType) {
            headers.append('Content-Type', contentType);
        }
    
        const options: RequestInit = {
            method: 'PUT',
            body: file,
            headers: headers
        };
        const response = await fetch(fullUrl, options);
        //console.log(response);
        if (!response.ok) {
            throw new Error(`API call failed: ${response.status} ${response.statusText}`);
        }
        return await response.ok;
    }
    
    // General request method
    private async request<T>(
        fullUrl: string,
        method: 'GET' | 'POST' | 'PUT',
        body?: any,
        headersInit?: HeadersInit
    ): Promise<T> {
        const headers = new Headers(headersInit);
        headers.append('X-WBAPI-JWT', this.token);
        headers.append('Content-Type', 'application/json');

        const options: RequestInit = {
            method,
            headers,
            body: (body)
        };
        const response = await fetch(fullUrl, options);
        if (!response.ok) {
            throw new Error(`API call failed: ${response.status} ${response.statusText}`);
        }
        return await response.json();
    }
}
