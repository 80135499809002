import React, { createContext, useContext, ReactNode, useEffect, useRef } from 'react';
import { ApiClient } from '../api/apiClient';
import { useAuth } from './AuthContext';
import { getConfig } from '../services/configService';

interface ApiClientContextType {
  apiClient: ApiClient;
}

const ApiClientContext = createContext<ApiClientContextType | undefined>(undefined);

interface ApiClientProviderProps {
  children: ReactNode;
}

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({ children }) => {
  const { token } = useAuth();
  const config = getConfig();
  const apiClientRef = useRef<ApiClient | null>(null);

  if (apiClientRef.current === null && token) {
    // Initialize with token and baseUrl
    apiClientRef.current = new ApiClient(token, config.apiEndpoint+"/");
  }

  return (
    <ApiClientContext.Provider value={{ apiClient: apiClientRef.current! }}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const useApiClient = (): ApiClient => {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error('useApiClient must be used within an ApiClientProvider');
  }
  return context.apiClient;
};