import { ApiClient } from './apiClient';

const VESSEL_API_ENDPOINT = 'vesselView/dataSources';

// Default payload
const payloadVessels = {
    imo: 3333333,
    dataType: "vessel_imo_mapping",
};

// Helper function to build query parameters
function buildQueryParams(params: Record<string, any>): string {
    return (
        '?' +
        Object.entries(params)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&')
    );
}

export class ApiVessels {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    /**
     * Fetches the list of vessels as a dictionary with vessel names as keys and IMO numbers as values.
     * @returns A promise resolving to a dictionary of vessel names to IMO numbers.
     */
    async fetchVessels(): Promise<{ [vesselName: string]: number }> {
        const queryParams = buildQueryParams(payloadVessels);
        const subUrl = `${VESSEL_API_ENDPOINT}${queryParams}`;
    
        try {
            // Await the response
            const response = await this.apiClient.get<{ data: Array<{ data: { [vesselName: string]: number } }> }>(subUrl);
    
            // Extract the dictionary from response.data[0].data
            if (response?.data?.length > 0 && response.data[0].data) {
                const vesselDict = response.data[0].data;
                return vesselDict; // Return the extracted vessel dictionary
            } else {
                console.warn("No data returned or unexpected response structure");
                return {}; // Return an empty dictionary if no data is available
            }
        } catch (error) {
            console.error("Error fetching vessels:", error);
            throw error;
        }
    }
}