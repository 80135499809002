import React from 'react';
import styles from './TextArea.module.scss'; // Import the styles

interface TextAreaProps {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>; // Added onKeyDown prop
  placeholder?: string;
  columnClass?: string;
  name?: string;
  rows?: number;
  scrollable?: boolean;
  showLabel?: boolean;
  disabled?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  label,
  value,
  onChange,
  onKeyDown, // Destructure the onKeyDown prop
  placeholder = '',
  columnClass = 'col',
  name,
  rows = 3,
  scrollable = false,
  showLabel = true,
  disabled = false,
}) => {
  return (
    <div className={`${columnClass} mb-2`}>
      {showLabel && <label htmlFor={id} className="form-label fw-semibold">{label}</label>}
      <textarea
        id={id}
        className={`form-control form-control-sm ${styles.textArea} ${scrollable ? styles.scrollable : ''}`}
        placeholder={placeholder}
        name={name}
        rows={rows}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}  // Add onKeyDown handler to the textarea element
        {...(disabled ? { disabled: true } : {})}
      />
    </div>
  );
};

export default TextArea;
