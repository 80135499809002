// src/contexts/AuthContext.tsx
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { getConfig } from '../services/configService';

interface AuthContextType {
  token: string | null;
  stateParams: string | null;
  loading: boolean;
  error: string | null;
  userData:{} | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const extractToken = (): string | null => {
    // In url we receive both access and id token, we need to extract id token
    let tokenMatch = /[?&#]id_token=([^&#]*)/.exec(window.location.href);
    let token = tokenMatch ? tokenMatch[1] : null;
    if (token) {
        return token;
    }else{
        return null;
    }
};

const extractStateParams = (): string | null => {
  const url = window.location.href;
  const stateParamStart = "state=";
  const stateParamEnd = "%24%24%24";
  
  if (url.includes(stateParamStart)) {
    const startIndex = url.indexOf(stateParamStart);
    const endIndex = url.indexOf(stateParamEnd);
    let state_variable = url.substring(startIndex + stateParamStart.length, endIndex);
    state_variable = state_variable.replace(/%24%24/g, "&");
    state_variable = state_variable.replace(/%3D/g, "=");
    return state_variable;
  }
  return null;
};



export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [token, setToken] = useState<string | null>(null);
    const [stateParams, setStateParams] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [userData, setUserData] = useState<{} | null>(null);
    const config = getConfig();

    function redirectToLogin(): void {
        //Redirect need to take the current url as state so that we get back to same page if re-login is needed
        try {
            //We want to send all parameters through the auth process. to do this, we change some special chars. seems we can send $ and =, but not & or #
            let page_parameters_array = window.location.href.match(/\?(.*)/);
            let page_parameters = "";
            if (page_parameters_array && page_parameters_array.length > 1) {
                page_parameters = page_parameters_array[1].replace(/&/g, "%24%24");
                page_parameters = page_parameters.replace("#",'');
            }
            window.location.href = config.redirect_url+ "&state=" + page_parameters + "%24%24%24";
        } catch (err: any) {
            window.alert("Error:" + err.message);
        }
    }

  useEffect(() => {
    const idToken = extractToken();
    const state = extractStateParams();
    //console.log(idToken);

    if (idToken) {
        setToken(idToken);
        setStateParams(state);
      
        // Verify the token with the API
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-WBAPI-JWT': `${idToken}`,
        });
      
        fetch(`${config.apiEndpoint}/userPermissions`, {
          method: 'GET',
          headers: myHeaders,
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Failed to fetch user permissions');
            }
            return response.json();
          })
          .then(data => {
            const permissions = data?.data?.permissions;
            // Check if permissions are available
            if (!permissions) {
              setError('No access: permissions not found.');
            } else if (!permissions.navilex) {
              setError('No access: navilex permissions not found.');
            } else {
              setUserData(data.data);
            }
            setLoading(false);
          })
          .catch(error => {
            console.error(error);
            setError('Failed to authenticate with the provided token.');
            setLoading(false);
          });
      } else {
        setError('Initiating SSO login...');
        setLoading(false);
        // Redirect to login page to get the token:
        redirectToLogin();
      }
  }, []);

  return (
    <AuthContext.Provider value={{ token, stateParams, loading, userData, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};