export interface ApiChatMessage {
  question: string;
  answer: string;
  created: string;
}

export enum MessageType {
  Question = "question",
  Answer = "answer",
}

export interface Message {
  text: string;
  type: MessageType;
  created: string;
}

