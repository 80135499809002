// src/components/Footer/Footer.tsx
import React from 'react';
import styles from './Footer.module.scss'; 

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <span>&copy; 2023 Powered by Western Ava. All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
