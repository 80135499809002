import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface PageHeaderProps {
  title: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <Row className="px-4 py-2">
      <Col className="display-6 px-2 m-0">{title}</Col>
    </Row>
  );
};

export default PageHeader;
