import React, { useState, useEffect, useRef } from 'react';
import './Chat.scss';
import { Message, MessageType } from '../../models/ChatInterfaces';
import TextArea from "../form/TextArea/TextArea";
import FormButton from '../form/FormButton/FormButton';
import PageRow from '../common/PageRow/PageRow';
import Spinner from 'react-bootstrap/Spinner';

interface ChatProps {
    messages: Message[];
    onMessageSubmit: (newPrompt: string,bubbleText:string) => void;
    maxHeight?: string; // Optional maxHeight prop to control the scrollable area
    isLoading: boolean;
}

const Chat: React.FC<ChatProps> = ({ messages, onMessageSubmit, maxHeight, isLoading }) => {
    const [inputText, setInputText] = useState('');
    const [selectedBubble, setSelectedBubble] = useState<number | null>(null); // Tracks selected bubble
    const [showCheck, setShowCheck] = useState(false); // Tracks if check icon is visible
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (messages.length > 1) {
            scrollToBottom();
        }
    }, [messages]);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (!isLoading) {
                handleQuestionSubmit();
            }
        }
    };

    const handleQuestionSubmit = () => {
        if (inputText.trim() !== '') {
            let finalMessage = inputText; // The default message to send
    
            // Retrieve the most recent question and response from the messages list
            const lastQuestion = messages
                .filter((msg) => msg.type === MessageType.Question)
                .slice(-1)[0]?.text || null;
    
            const lastResponse = messages
                .filter((msg) => msg.type !== MessageType.Question)
                .slice(-1)[0]?.text || null;
    
            if (lastQuestion && lastResponse) {
                // Append previous question and response for context
                finalMessage = `You will receive a question. The question might be related to previous question and response, but not always.
                Prev question: ${lastQuestion}, your prev response: ${lastResponse}. 
                This is my new question: ${inputText}`;
            }

            // Send finalMessage to the backend
            onMessageSubmit(finalMessage,inputText);
    
            // Add the new question (inputText only) to the chat bubbles
            const updatedMessages = [
                ...messages,
                { type: MessageType.Question, text: inputText },
            ];
    
            setInputText(''); // Clear the input field
        }
    };

    const handleBubbleClick = (index: number) => {
        setSelectedBubble((prev) => (prev === index ? null : index)); // Deselect if clicking the same bubble
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text); // Copy text to clipboard
        setShowCheck(true);
        setTimeout(() => setShowCheck(false), 2000); // Show check icon for 2 seconds
    };

    const chatContentStyle: React.CSSProperties = {
        maxHeight,
        overflowY: maxHeight ? 'auto' : 'visible'
    };

    return (
        <div className="chat-container">
            <PageRow className="pb-2 px-2">
                <div className="chat-content col-md-12 mb-2" style={chatContentStyle}>
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`chat-bubble ${
                                msg.type === MessageType.Question ? "chat-bubble-right" : "chat-bubble-left"
                            } ${selectedBubble === index ? "chat-bubble-selected" : ""}`}
                            onClick={() => handleBubbleClick(index)}
                        >
                            <span className="chat-text">{msg.text}</span>
                            {selectedBubble === index && (
                                <span
                                    className="copy-icon"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering bubble click
                                        handleCopyClick(msg.text);
                                    }}
                                >
                                    <i className={`bi ${showCheck ? "bi-check" : "bi-copy"}`} />
                                </span>
                            )}
                        </div>
                    ))}
                    <div ref={messagesEndRef} /> {/* Invisible div for scrolling into view */}
                </div>
                {isLoading && (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                )}
            </PageRow>
            <PageRow>
                <TextArea
                    id="chat-question-area"
                    label="Question:"
                    name="Question"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Type your message here"
                    rows={5}
                    scrollable={true}
                    columnClass="col-md-12 px-2"
                    showLabel={false}
                />
            </PageRow>
            <PageRow>
                <FormButton
                    label="Ask Ava"
                    onClick={handleQuestionSubmit}
                    columnClass="col-md-4 ms-auto px-2"
                    disabled={inputText.trim() === '' || isLoading}
                />
            </PageRow>
        </div>
    );
};

export default Chat;