import React, { useState, useEffect } from 'react';
import { JobClass } from '../../../models/JobClass'; // Import the JobClass model

// Import the SCSS file for styling
import './JobList.scss';

interface JobListProps {
  jobs: JobClass[];
  onSelectJob: (jobId: string) => void;
  selectedJob?: string;
  maxHeight?: string; // Add maxHeight property
}

const JobList: React.FC<JobListProps> = ({ jobs, onSelectJob, selectedJob, maxHeight = '100%' }) => {
  const [scrollToJobId, setScrollToJobId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedJob) {
      setScrollToJobId(selectedJob);
    }
  }, [selectedJob]);

  const handleJobClick = (jobId: string) => {
    onSelectJob(jobId);
  };

  return (
    <div className="overflow-auto" style={{ maxHeight }}>
      <table className="table-custom">
        <tbody>
          {jobs.map((job) => (
            <tr
              key={job.jobId}
              className={`cursor-pointer ${job.jobId === selectedJob ? 'selected-row' : ''}`}
              onClick={() => handleJobClick(job.jobId)}
            >
              <td>
                {job.createdOrUpdatedTimestamp
                  ? new Date(job.createdOrUpdatedTimestamp).toISOString().slice(0, 10)
                  : ''}{' '}
                - {job.jobMetadata?.jobName || 'Unnamed Job'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobList;