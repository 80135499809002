import React, { useState } from 'react';
import {Workflow, PromptGroup,MandatoryPrompt,MandatoryPromptResponse,PromptResponse,WorkflowResponse } from '../../models/ContractInterfaces';
import './WorkflowPrompts.scss';

interface WorkflowPromptsProps {
    workflowResponses: WorkflowResponse[];
    workflow: Workflow | null;
}
interface SanitizedJSON {
    answer: string;
    citations: string[];
}

const WorkflowPrompts: React.FC<WorkflowPromptsProps> = ({ workflowResponses, workflow }) => {
    // States
    const [visibleCitations, setVisibleCitations] = useState<{ [key: string]: boolean }>({});
    const [showAllPrompts, setShowAllPrompts] = useState<{ [key: string]: boolean }>({});

    
    // Group, order and sort the prompts:
    const groupAllPrompts = (): Map<string, Array<PromptResponse | MandatoryPromptResponse>> => {
        // Initialize a combined groups map
        const combinedGroups = new Map<string, Array<PromptResponse | MandatoryPromptResponse>>();
    
        // Helper: Add items to the combined groups map
        const addToCombinedGroups = (
            groupId: string,
            items: Array<PromptResponse | MandatoryPromptResponse>
        ) => {
            if (!combinedGroups.has(groupId)) {
                combinedGroups.set(groupId, []);
            }
            const existingItems = combinedGroups.get(groupId) || [];
            combinedGroups.set(groupId, [...existingItems, ...items]);
        };
    
        // Step 1: Process Prompt Groups
        const processPromptGroups = () => {
            const promptGroups = new Map<string, PromptResponse[]>();
            promptResponseList.forEach((prompt) => {
                const groupId = prompt.promptGroupId;
                const group = promptGroups.get(groupId) || [];
                group.push(prompt);
                promptGroups.set(groupId, group);
            });
    
            promptGroups.forEach((prompts, groupId) => {
                const sortedPrompts = sortPromptsById(prompts);
                addToCombinedGroups(groupId, sortedPrompts);
            });
        };
    
        // Step 2: Process Mandatory Groups
        const processMandatoryGroups = () => {
            const mandatoryGroups = new Map<string, MandatoryPromptResponse[]>();
            mandatoryWithAdvice.forEach((mandatory) => {
                const groupId = mandatory.promptGroupId;
                const group = mandatoryGroups.get(groupId) || [];
                group.push(mandatory);
                mandatoryGroups.set(groupId, group);
            });
    
            mandatoryGroups.forEach((mandatoryPrompts, groupId) => {
                if (!combinedGroups.has(groupId)) {
                    const sortedMandatoryPrompts = sortMandatoryPromptsById(mandatoryPrompts);
                    addToCombinedGroups(groupId, sortedMandatoryPrompts);
                }
            });
        };
    
        // Helper: Sort prompt responses by ID
        const sortPromptsById = (prompts: PromptResponse[]): PromptResponse[] => {
            return prompts.sort((a, b) => parseInt(a.promptId, 10) - parseInt(b.promptId, 10));
        };
    
        // Helper: Sort mandatory prompt responses by ID
        const sortMandatoryPromptsById = (
            mandatoryPrompts: MandatoryPromptResponse[]
        ): MandatoryPromptResponse[] => {
            return mandatoryPrompts.sort(
                (a, b) =>
                    parseInt(a.mandatoryPromptId, 10) - parseInt(b.mandatoryPromptId, 10)
            );
        };
    
        // Step 3: Execute processing steps
        processPromptGroups();
        processMandatoryGroups();
    
        // Return the final combined groups
        return combinedGroups;
    };
    // Type guard to check if a WorkflowResponse is a PromptResponse
    function isPromptResponse(response: WorkflowResponse): response is PromptResponse {
        return (response as PromptResponse).promptId !== undefined;
    }
    // Type guard to check if a WorkflowResponse is a MandatoryPromptResponse
    function isMandatoryPromptResponse(response: WorkflowResponse): response is MandatoryPromptResponse {
        return (response as MandatoryPromptResponse).mandatoryPromptId !== undefined;
    }


    // Helpers
    const getMandatoryPromptImportance = (
            promptGroupId: string,
            mandatoryPromptId: string
        ): string => {
            if (!workflow) {
                console.error("Workflow is not defined.");
                return "NEUTRAL"; // Default importance
            }
        
            // Find the relevant PromptGroup
            const promptGroup = workflow.promptGroups.find((group) => group.promptGroupId === promptGroupId);
        
            if (!promptGroup) {
                console.error(`PromptGroup with ID ${promptGroupId} not found.`);
                return "NEUTRAL"; // Default importance
            }
        
            // Find the relevant MandatoryPrompt
            const mandatoryPrompt = promptGroup.mandatoryPrompts.find(
                (prompt) => prompt.mandatoryPromptId === mandatoryPromptId
            );
        
            if (!mandatoryPrompt) {
                console.error(
                    `MandatoryPrompt with ID ${mandatoryPromptId} not found in PromptGroup ${promptGroupId}.`
                );
                return "NEUTRAL"; // Default importance
            }
        
            // Return the promptImportance
            return mandatoryPrompt.promptImportance || "NEUTRAL"; // Default to NEUTRAL if undefined
    };
    const getPromptId = (prompt: PromptResponse | MandatoryPromptResponse): string => {
        return (prompt as PromptResponse).promptId || (prompt as MandatoryPromptResponse).mandatoryPromptId;
    };
    const getPromptGroupDetails = (promptGroupId: string): PromptGroup | undefined => {
        return workflow?.promptGroups.find(group => group.promptGroupId === promptGroupId);
    };
    const getPromptKey = (prompt: PromptResponse): string => `${prompt.promptGroupId}-${prompt.promptId}`;

    // Filter out prompts that should not be shown
    function shouldIncludePrompt(prompt: PromptResponse): boolean {
        if (prompt.promptType !== 'COMPARE') {
          return true; // Include prompts that are not of type 'COMPARE'
        }
      
        // Use type assertion to treat prompt.answer as any
        const answerAny = prompt.answer as any;
      
        // Check if prompt.answer is an object with an 'answer' property
        if (
          typeof answerAny === 'object' &&
          answerAny !== null &&
          'answer' in answerAny &&
          typeof answerAny.answer === 'boolean'
        ) {
          // Exclude prompts where prompt.answer.answer is true
          return answerAny.answer !== true;
        }
      
        // If prompt.answer is not an object with 'answer', include the prompt
        return true;
    }
    function isAnswerObject(answer: any): answer is { answer: string } {
        return (
            typeof answer === 'object' &&
            answer !== null &&
            'answer' in answer &&
            typeof answer.answer === 'string'
        );
    }
    function shouldIncludeMandatoryPrompt(prompt: MandatoryPromptResponse): boolean {
        if (isAnswerObject(prompt.answer)) {
            const answerValue = String(prompt.answer.answer).toLowerCase();
            const expectedValue = String(prompt.expectedResult).toLowerCase();
            // Exclude the prompt if answerValue equals expectedValue
            return answerValue !== expectedValue;
        }
    
        // If 'answer' property is not present or types don't match, include the prompt
        return true;
    }
    

    // Formatting
    const renderCitations = (promptKey: string, parsedAnswer: any): JSX.Element | null => {
        if (visibleCitations[promptKey] && parsedAnswer?.citations?.length > 0) {
            return (
                <div className="mt-2">
                    <strong>Citations:</strong>
                    <ul className="list-unstyled">
                        {parsedAnswer.citations.map((citation: string, index: number) => (
                            <li key={index}>{citation}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const sanitizeAndExtractJSON = (malformedJson: string): string => {
        let sanitizedAnswer = '';
        let sanitizedCitations: string[] = [];
        
        try {
            // Step 1: Extract the 'answer' field using regex
            const answerRegex = /"answer"\s*:\s*"([^"\\]*(\\.[^"\\]*)*)"/;
            const answerMatch = malformedJson.match(answerRegex);
            if (answerMatch && answerMatch[1]) {
                sanitizedAnswer = answerMatch[1]
                    .replace(/\\/g, '\\\\')  // Escape backslashes
                    .replace(/"/g, '\\"');    // Escape double quotes
            } else {
                console.error("Failed to extract 'answer' from the JSON string.");
                sanitizedAnswer = 'No answer available.';
            }
    
            // Step 2: Robustly locate the 'citations' array using regex
            const citationsRegex = /"citations"\s*:\s*\[/;
            const citationsStartMatch = citationsRegex.exec(malformedJson);
            if (citationsStartMatch) {
                const citationsStartIndex = citationsStartMatch.index + citationsStartMatch[0].length;
                
                // Find the position of the last closing bracket ']'
                const citationsEndIndex = malformedJson.lastIndexOf(']');
                if (citationsEndIndex === -1 || citationsEndIndex < citationsStartIndex) {
                    console.error("Failed to locate the end of 'citations' array in the JSON string.");
                    sanitizedCitations = [];
                } else {
                    // Extract the substring containing all citations
                    const citationsSubstring = malformedJson.substring(
                        citationsStartIndex,
                        citationsEndIndex
                    );
    
                    // Use a regex to extract all strings within quotes
                    const citationItemRegex = /"([^"\\]*(\\.[^"\\]*)*)"/g;
                    let match;
                    const citations: string[] = [];
    
                    while ((match = citationItemRegex.exec(citationsSubstring)) !== null) {
                        if (match[1]) {
                            // Clean each citation by removing extraneous backslashes and quotes
                            let cleanedCitation = match[1]
                                .replace(/\\"/g, '"')        // Replace \" with "
                                .replace(/\\\\/g, '\\')      // Replace \\ with \
                                .trim();
                            
                            // Remove leading and trailing quotes if present
                            if (cleanedCitation.startsWith('"') && cleanedCitation.endsWith('"')) {
                                cleanedCitation = cleanedCitation.slice(1, -1);
                            }
    
                            // Check if the citation starts with [number]
                            const citationStartRegex = /^\[\d+\]/;
                            if (citationStartRegex.test(cleanedCitation)) {
                                // Citation starts with [number], keep as is
                                citations.push(cleanedCitation);
                            } else {
                                // Attempt to split citations based on [number] pattern within the string
                                // This handles cases where multiple citations are concatenated without proper separation
                                const splitCitations = cleanedCitation.split(/(?=\[\d+\])/);
                                splitCitations.forEach(citation => {
                                    const trimmedCitation = citation.trim();
                                    if (trimmedCitation.length > 0) {
                                        citations.push(trimmedCitation);
                                    }
                                });
                            }
                        }
                    }
    
                    // Assign the cleaned citations
                    sanitizedCitations = citations;
                }
            } else {
                console.error("Failed to locate 'citations' array in the JSON string.");
                sanitizedCitations = [];
            }
    
            // Step 3: Construct the sanitized JSON
            const sanitizedJSON = JSON.stringify({
                answer: sanitizedAnswer,
                citations: sanitizedCitations
            });
    
            return sanitizedJSON;
        } catch (error) {
            console.error("An unexpected error occurred while sanitizing the JSON:", error);
            // Return a default JSON string in case of errors
            return JSON.stringify({
                answer: 'No answer available.',
                citations: []
            });
        }
    };

    const formatPromptAnswerContent = (prompt: PromptResponse): JSX.Element => {
        const promptKey = getPromptKey(prompt);
        let parsedAnswer: any;
        if (typeof prompt.answer === 'string') {
            try {
                parsedAnswer = JSON.parse(prompt.answer || '{}');
            } catch (error) {

                // Try to fix the JSON string
                const fixedAnswerString = sanitizeAndExtractJSON(prompt.answer);
                try {
                    parsedAnswer = JSON.parse(fixedAnswerString);
                } catch (error) {
                    console.error('Error parsing fixed answer v3:', fixedAnswerString);
                    parsedAnswer = null;
                }
            }
        } else if (typeof prompt.answer === 'object') {
            parsedAnswer = prompt.answer;
        } else {
            parsedAnswer = null;
        }
    
        const hasCitations = parsedAnswer && Array.isArray(parsedAnswer.citations) && parsedAnswer.citations.length > 0;
        const hasAdvice = prompt.promptAdvice && prompt.promptAdvice !== '';

        const checkSingleLineResult = (promptType: string): boolean => {
            return promptType !== 'BOOLEAN' && promptType !== 'TEXT MULTILINE' && promptType !== 'COMPARE';
        };
        const getClassNamePromptAnswer = (promptType: string): string => {
            return checkSingleLineResult(promptType)
                ? 'promptSingleLineAnswer p-1'
                : 'promptAnswer p-4';
        };
        const renderAdvice = () => {
            if (!hasAdvice) return null;
    
            const adviceClass = prompt.promptImportance === 'HIGH' ? 'high-importance' : 'normal-importance';
            return (
                <p>
                    <strong className={adviceClass}>
                        Advice ({prompt.promptImportance} {prompt.promptImportance === 'HIGH' ? 'Risk' : ''}):
                    </strong> {prompt.promptAdvice}
                </p>
            );
        };
    
        const renderRefSuperscript = () => {
            if (prompt.enableCitation && hasCitations) {
                return (
                    <sup>
                        <a
                            href="#"
                            className="toggle-link"
                            onClick={(e) => { e.preventDefault(); toggleCitations(promptKey); }}
                        >
                            (citations)
                        </a>
                    </sup>
                );
            }
            return null;
        };
    
        return (
            <div className={`mb-1 ${getClassNamePromptAnswer(prompt.promptType)}`} key={getPromptKey(prompt)}>
                {prompt.promptType === 'BOOLEAN' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {prompt.promptType === 'TEXT MULTILINE' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        
                        {parsedAnswer && <p>{parsedAnswer.answer}</p>}
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {prompt.promptType === 'COMPARE' && (
                    <>
                        <h6>
                            {prompt.promptName}
                        </h6>
                        {parsedAnswer && <p>{parsedAnswer.answer}</p>}
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
                {checkSingleLineResult(prompt.promptType) && (
                    <>
                        <strong>{prompt.promptName}: </strong>
                        {parsedAnswer && <span>{parsedAnswer.answer}</span>}
                        {!parsedAnswer && typeof prompt.answer === 'string' && <span>{prompt.answer}</span>}
                        <br />
                        {renderAdvice()}
                        {renderRefSuperscript()}
                        {renderCitations(promptKey, parsedAnswer)}
                    </>
                )}
            </div>
        );
    };
    const formatMandatoryContent = (mandatoryPrompt: MandatoryPromptResponse): JSX.Element => {
        const promptKey = mandatoryPrompt.mandatoryPromptId;
    
        // Retrieve promptImportance dynamically
        const promptImportance = getMandatoryPromptImportance(
            mandatoryPrompt.promptGroupId,
            mandatoryPrompt.mandatoryPromptId
        );
    
        const renderAdvice = () => {
            if (!mandatoryPrompt.promptAdvice || !mandatoryPrompt.promptAdvice) return null;
    
            const adviceClass = promptImportance === 'HIGH' ? 'high-importance' : 'normal-importance';
            return (
                <p>
                    <strong className={adviceClass}>
                        Advice ({promptImportance} {promptImportance === 'HIGH' ? 'Risk' : ''}):
                    </strong>{' '}
                    {mandatoryPrompt.promptAdvice}
                </p>
            );
        };
    
        return (
            <div key={promptKey} className="mb-1 promptAnswer p-4">
                <h6>{mandatoryPrompt.mandatoryPromptName}</h6>
                {renderAdvice()}
            </div>
        );
    };


    // Toggling
    const toggleCitations = (promptKey: string) => {
        setVisibleCitations(prevState => ({
            ...prevState,
            [promptKey]: !prevState[promptKey]
        }));
    };
    const toggleShowAllPrompts = (groupId: string) => {
        setShowAllPrompts(prevState => ({
            ...prevState,
            [groupId]: !prevState[groupId]
        }));
    };

    // Separate the responses into two lists
    const promptResponseList = workflowResponses.filter(isPromptResponse).filter(shouldIncludePrompt);
    const mandatoryResponseList = workflowResponses.filter(isMandatoryPromptResponse).filter(shouldIncludeMandatoryPrompt);
    const mandatoryWithAdvice = mandatoryResponseList.filter(
        (mandatory) => mandatory.promptAdvice && mandatory.promptAdvice !== ''
    );

    // Group and sort the prompt Groups:
    const groupedPrompts = groupAllPrompts();

    // Helper functions:
    if (groupedPrompts.size === 0) {
        return <p>No findings available.</p>;
    }

    return (
        <div>
            {Array.from(groupedPrompts.keys())
                .sort((a, b) => parseInt(a, 10) - parseInt(b, 10)) // Sort keys numerically
                .map((groupId) => {
                const prompts = groupedPrompts.get(groupId);
                const promptGroup = getPromptGroupDetails(groupId);

                // Use maxResponsesToShow from the prompt group or default to 3
                const maxResponsesToShow = promptGroup?.maxResponsesToShow ?? 3;
                const showAll = showAllPrompts[groupId] || maxResponsesToShow === 999;

                const shouldShowToggle = prompts && prompts.length > maxResponsesToShow && maxResponsesToShow !== 999;

                return (
                    <div key={groupId} className="pb-3">
                        {promptGroup && (
                            <>
                                <h3>{promptGroup.promptGroupName}</h3>
                                <p>{promptGroup.promptGroupDescription}</p>
                            </>
                        )}

                        {shouldShowToggle && (
                            <p>
                                Found {prompts.length} issues.{' '}
                                <a
                                    className="toggle-link"
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        toggleShowAllPrompts(groupId);
                                    }}
                                >
                                    {showAll ? 'Hide' : 'Show all'}
                                </a>
                            </p>
                        )}

                            {prompts?.slice(0, showAll ? prompts.length : maxResponsesToShow).map((prompt) => (
                                <React.Fragment key={getPromptId(prompt)}>
                                    {'promptId' in prompt
                                        ? formatPromptAnswerContent(prompt as PromptResponse)
                                        : formatMandatoryContent(prompt as MandatoryPromptResponse)}
                                </React.Fragment>
                            ))}

                        <hr className="divider" />
                    </div>
                );
            })}
        </div>
    );
};

export default WorkflowPrompts;