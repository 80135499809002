import React, { useRef, useState, useEffect } from 'react';
import "./FileDropArea.scss";
import PageRow from '../../common/PageRow/PageRow';

// Define the props interface
interface FileDropAreaProps {
  onFileDrop: (files: File[]) => void;
  pastedTextFileName: string;
  onTotalSizeChange?: (totalSizeMB: number) => void; // New optional prop
}

// Utility function to format size in MB with two decimal places
const formatSizeMB = (sizeInBytes: number): string => {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
};

// Define the functional component
const FileDropArea: React.FC<FileDropAreaProps> = ({ onFileDrop, pastedTextFileName, onTotalSizeChange }) => {
    // Create a reference to the file input element
    const fileInputRef = useRef<HTMLInputElement>(null);

    // State to hold the files, initializing with the dummy file "Recap.pdf"
    const [files, setFiles] = useState<File[]>([{ name: pastedTextFileName } as File]);

    // Function to validate if the file is a PDF
    const isValidPdfFile = (file: File) => file.type === 'application/pdf' || file.name.toLowerCase().endsWith('.pdf');

    // Effect to call onFileDrop and onTotalSizeChange whenever files change
    useEffect(() => {
        onFileDrop(files);
        if (onTotalSizeChange) {
            const totalSizeBytes = files.reduce((acc, file) => acc + (file.size || 0), 0);
            const totalSizeMB = parseFloat((totalSizeBytes / (1024 * 1024)).toFixed(2));
            onTotalSizeChange(totalSizeMB);
        }
    }, [files, onFileDrop, onTotalSizeChange]);

    // Handle file drag over
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    // Handle file drop
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files).filter(isValidPdfFile);
        const updatedFiles = [...files.slice(1), ...droppedFiles];
        setFiles([files[0], ...updatedFiles]);
    };

    // Handle file input change
    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files ? Array.from(event.target.files).filter(isValidPdfFile) : [];
        const updatedFiles = [...files.slice(1), ...selectedFiles];
        setFiles([files[0], ...updatedFiles]);
    };

    // File input click handler
    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Delete file (not the first one)
    const handleDeleteFile = (index: number) => {
      if (files[index].name === pastedTextFileName) return; // Prevent deletion of "Pasted_Text.pdf"
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    };

    // Move file up
    const moveFileUp = (index: number) => {
      if (index === 0) return; // Prevent moving up the first file
      const newFiles = [...files];
      [newFiles[index - 1], newFiles[index]] = [newFiles[index], newFiles[index - 1]];
      setFiles(newFiles);
    };

    // Move file down
    const moveFileDown = (index: number) => {
      if (index === files.length - 1) return; // Prevent moving down the last file
      const newFiles = [...files];
      [newFiles[index + 1], newFiles[index]] = [newFiles[index], newFiles[index + 1]];
      setFiles(newFiles);
    };

    return (
        <div className="h-100 d-flex flex-column">
          <PageRow>
            <label htmlFor="file-drop-area" className="form-label fw-semibold px-2 col">Upload Documents (pdf only):</label>
          </PageRow>
          <PageRow className='pb-2 px-2'>
            <div className="col-md-12 file-drop-area"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleFileInputClick}>
              <input
                id="file-drop-area"
                className="form-control form-control-sm invisible px-2"
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                multiple
                accept=".pdf, application/pdf"
              />
              <div className="drop-area-text pb-3">
                <i className="bi bi-cloud-upload-fill fs-3"></i>
                Drop Area
              </div>
            </div>
          </PageRow>
          <PageRow>
            <label htmlFor="list-files-table" className="form-label fw-semibold px-2 col">Contract Documents:</label>
          </PageRow>
          <PageRow>
            <div className="col-md-12 list-files-table px-2 mx-0">
              <table className="table-custom">
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>File Name</th>
                    <th>Size (MB)</th> {/* New column for size */}
                    <th className="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
                {files.map((file, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{file.name}</td>
                      <td>{file.size ? formatSizeMB(file.size) : '—'}</td> {/* Display size */}
                      <td className="text-end">
                        <>
                          <button 
                            className="btn btn-link p-0" 
                            title="Move up" 
                            onClick={() => moveFileUp(index)} 
                            disabled={index === 0}>
                            <i className="bi bi-arrow-up"></i>
                          </button>
                          <button 
                            className="btn btn-link p-0" 
                            title="Move down" 
                            onClick={() => moveFileDown(index)} 
                            disabled={index === files.length - 1}>
                            <i className="bi bi-arrow-down"></i>
                          </button>
                          <button 
                            className="btn btn-link p-0" 
                            title="Delete file" 
                            onClick={() => handleDeleteFile(index)} 
                            disabled={file.name === pastedTextFileName}>
                            <i className="bi bi-trash"></i>
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </PageRow>
          <PageRow className="mt-2 px-2">
            <div className="col-md-12">
              <strong>Total Size:</strong> {files.reduce((acc, file) => acc + (file.size || 0), 0) ? `${formatSizeMB(files.reduce((acc, file) => acc + (file.size || 0), 0))} MB` : '—'}
            </div>
          </PageRow>
        </div>
      );
};

// Export the component
export default FileDropArea;