import { ApiClient } from './apiClient';
import { v4 as uuidv4 } from 'uuid';
import { Job,JobFile } from '../models/JobInterface';  // Importing the Job interface
import {ContractClass} from '../models/ContractClass';  // Importing the ContractClass interface
import {JobClass} from '../models/JobClass';

interface FetchResponsePresignedUrl {
    data:{
        presignedurl: string;
    }
}
interface FetchResponseJobs {
    data: Job[];
}
export class ApiJob {
    private apiClient: ApiClient;
    public jobId:string;
    private conversationId:string;

    constructor(apiClient: ApiClient,jobId?:string,conversationId?:string) {
        this.apiClient = apiClient;
        if(jobId){
            this.jobId = jobId;
        }else{
            this.jobId =  uuidv4();
        }
        if(conversationId){
            this.conversationId = conversationId;
        }
        else{
            this.conversationId = uuidv4();
        }
    }

    async uploadFilesAndCreateJob(
        files: File[],
        userId: string,
        contract: ContractClass,
        createdAt: string,
        workflowId: string,
        jobName: string,
        jobComments: string,
        contractRelations?: Record<string, any> // Optional object for additional metadata
    ): Promise<boolean> {
        try {
            // Upload all files in parallel, but give the uploadSingleFile method the order, starting from 1:
            const uploadPromises = files.map((file, index) => this.uploadSingleFile(file, index + 1, userId));
            await Promise.all(uploadPromises);
    
            // Call the createJob method to create a job with JSON metadata
            const newJob: Job = {
                jobId: this.jobId,
                userId: userId,
                jobMetadata: {
                    jobComments: jobComments,
                    jobName: jobName,
                },
                contractMetadata: {
                    contractId: contract.contractId,
                    createdAt: createdAt,
                },
                workflowId: workflowId,
                conversationId: this.conversationId,
                ...(contractRelations !== undefined ? { contractRelations } : { contractRelations: {} }) // Always include contractRelations
            };
    
            const createJobResponse = await this.createJob(newJob);
            return true;
        } catch (error) {
            console.error('Error during the upload process:', error);
            return false;
        }
    }
    
    private async uploadSingleFile(file: File,order: number,user:string): Promise<void> {
        // create a new file name, but add _order to the end of the file name, but before file extension
        const fileParts = file.name.split('.');
        const fileExtension = fileParts.pop();
        const newFileName = `${fileParts.join('.')}-${order}.${fileExtension}`;
        const jobFile:JobFile = {
            userId: user,
            jobId: this.jobId,
            fileName: newFileName
        };
    
        try {
            // Making a POST request and specifying the expected response structure
            const response = await this.apiClient.post<FetchResponsePresignedUrl>('genai/uploadFile', JSON.stringify(jobFile), {
                'Content-Type': 'application/json'
            });

            // Parse the JSON string within the response body to get the actual data
            const presignedUrl = response.data.presignedurl;
            const contentType = "application/pdf";
            await this.apiClient.putFile(presignedUrl, file, contentType);
        } catch (error) {
            throw new Error(`Error uploading file: ${file.name}`);
        }
    }
    
    // Function to create a job with JSON metadata using POST
    private async createJob(metadata: any): Promise<any> {
        const subUrl = 'genai/storeData?dataType=jobMetadata';
        return this.apiClient.post(subUrl, JSON.stringify(metadata));
    }

    async getJobStatus(jobId:string,userId:string): Promise<any> {
        const subUrl = 'genai/fetchData?dataType=jobMetadata';
        const payload = {userId:userId,jobId:jobId};
        const response = await this.apiClient.post(subUrl, JSON.stringify(payload));
        return response;
    }

    // Not important this year, but need pagination here:
    async fetchAllRecentJobs(userId: string,contractType:string): Promise<JobClass[]> {
        const subUrl = "genai/fetchData?dataType=jobMetadata";
        const payload = userId ? { userId: userId } : {};
        try {
            const response = await this.apiClient.post<FetchResponseJobs>(subUrl, JSON.stringify(payload));
            if (response.data && response.data.length > 0) {
                // Filter out jobs with status "COMPLETED"
                const completedJobs = response.data.filter((jobData: any) => jobData.status === 'COMPLETED');
                // Filter out jobs with the contractType
                const filteredJobs = completedJobs.filter((jobData: any) => jobData.contractMetadata.contractId === contractType);
                // Map the filtered response data to JobClass instances using the partial constructor
                const jobs: JobClass[] = filteredJobs.map((jobData: any) => new JobClass(jobData));
            
                // Sort the jobs based on the "createdOrUpdatedTimestamp"
                jobs.sort((a, b) => {
                    const timestampA = a.createdOrUpdatedTimestamp ? new Date(a.createdOrUpdatedTimestamp).getTime() : 0;
                    const timestampB = b.createdOrUpdatedTimestamp ? new Date(b.createdOrUpdatedTimestamp).getTime() : 0;
                    return timestampB - timestampA; // Sort in descending order
                });
            
                return jobs;
            } else {
                return [];
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
            return [];
        }
    }
}


