import React , { useState,useEffect,useRef } from 'react';
// Context:
import { usePage } from '../../contexts/PageContext';
//Interfaces and classes:
import {ContractClass} from '../../models/ContractClass';
import{JobClass} from '../../models/JobClass';
import {Message, MessageType } from '../../models/ChatInterfaces';
import {Workflow,WorkflowResponse} from '../../models/ContractInterfaces'
import {ContractMetadata, ProcessedWorkflow} from '../../models/JobInterface'
// Forms and components:
import PageHeader from "../../components/common/PageHeader/PageHeader";
import PageRow from "../../components/common/PageRow/PageRow";
import Card from "../../components/common/Card/Card";
import Chat from '../../components/chat/Chat';
import TextInput from '../../components/form/TextInput/TextInput';
import FormButton from '../../components/form/FormButton/FormButton';
import TextArea from "../../components/form/TextArea/TextArea";
import SelectDropdown from '../../components/form/SelectOption/SelectDropdown';
import JobStatus from '../../components/status/JobStatus';
import JobList from '../../components/list/jobList/JobList';
import WorkflowPrompts from '../../components/workflowPrompts/WorkflowPrompts';
import {useUser } from '../../contexts/UserContext';
import DocumentLinks from '../../components/documentLinks/DocumentLinks';
import DropDownSearch from '../../components/form/DropDownSearch/DropDownSearch';
// API
import {useApiClient } from '../../contexts/ApiContext';
import {ApiContract, ContractListData} from '../../api/apiContract';
import {ApiJob} from '../../api/apiJob';
import {ApiChat} from '../../api/apiChat';
import {ApiWorkflow} from '../../api/apiWorkflow';
import {ApiVessels} from '../../api/apiVessels';
import {ApiDocuments } from '../../api/apiDocuments';
import {ApiUsers} from '../../api/apiUser';
import { url } from 'inspector';

const DefaultContent: React.FC = () => {
    // API
    const apiClient = useApiClient();
    const [apiContract] = useState<ApiContract>(new ApiContract(apiClient));
    const [apiDocuments] = useState<ApiDocuments>(new ApiDocuments(apiClient));
    const [apiChat,setApiChat] = useState<ApiChat | null>(null);
    // Initial chat message:
    const initialMessage: Message = {
        text: "Hello, I'm Ava! You can ask me about the contract and I will do my best to answer you.",
        type: MessageType.Answer,
        created: new Date().toISOString() // Captures the current time as the message creation time
    };
    // Page parameters:
    const {updateParams, getParam} = usePage();
    // Dynamic Data
    const [contractOptions, setContractOptions] = useState([{ value: 'Select Contract', label: 'Select Contract' }]);
    const [workflowOptions,setWorkflowOptions] = useState([{ value: 'Select Workflow', label: 'Select Workflow' }]);
    const [contractDefinitionList, setContractDefinitionList] = useState<ContractListData[]>([]);
    const [jobList, setJobList] = useState<JobClass[]>([]);
    const [messages, setMessages] = useState<Message[]>([initialMessage]);
    const [workflowResponseList, setWorkflowResponseList] = useState<WorkflowResponse[]>([]);
    const [selectedWorkflow,setWorkflow] = useState<Workflow | null>(null);
    const [documentLinks, setDocumentLinks] = useState<{ name: string; url: string }[]>([]);
    // Url data
    const [urlJobId] = useState<string>(getParam('job')??'');
    const [urlContractId] = useState<string>(getParam('contract')??'');
    const [urlUserId]= useState<string>(getParam('user')??'');
    const [urlWorkflowId]= useState<string>(getParam('workflow')??'');
    const [urlDocSize, setUrlDocSize]= useState<string>(getParam('size')??'1');
    // Form
    const [showLoadScreen,setShowLoadScreen] = useState<boolean>(true);
    const [updateWorkflowLoadScreen,setUpdateWorkflowLoadScreen] = useState<boolean>(false);
    const [jobStatus,setJobStatus] = useState('LOADING');
    const [jobProgress,setJobProgress] = useState(5);
    const [workFlowNotificationMessage,setWorkflowNotificationMessage] = useState('');
    // Classes:
    const [job,setJob] = useState<JobClass | null>(null);
    // States:
    const [selectedContractId, setSelectedContractId] = useState('Select Contract');
    const [selectedContract, setSelectedContract] = useState<ContractClass | null>(null);
    const [selectedWorkflowId, setSelectedWorkflowId] = useState('Select Workflow');
    const [users, setUsers] = useState<string[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [isWaitingForChatResponse, setIsWaitingForChatResponse] = useState(false);
    const [vesselName,setVesselName] = useState('');
    const [vesselDictionary, setVesselDictionary] = useState<{ [imo: string]: string }>({});
    // User - set the current user as the default user:
    const { user,getUserId } = useUser();
    const currentUserId = getUserId()?? 'unknown';

    // References used to keep states for initial runs to stop re-run when rendering
    const initialLoadUrl = useRef(true);
    const initialLoad = useRef(true);
    const isJobCompleted = useRef(false);
    const timerStarted = useRef(false);

    // Fetch initial data (once):
    useEffect(() => {
        if(initialLoad.current){
            initialLoad.current = false;

            // Fetch data from API:
            try{
                const newContractOptions = [
                    { value: 'Select Contract', label: 'Select Contract' },
                ];
                const fetchContracts = async () => {
                    const contractList = await apiContract.fetchContractListFromApi();
                    setContractDefinitionList(contractList);
                    // Create options for the dropdown
                    contractList.forEach((contract: { contractId: string; contractName: string; }) => {
                        newContractOptions.push({ value: contract.contractId, label: contract.contractName });
                    });
                    setContractOptions(newContractOptions);
                };
                const fetchVessels = async () => {
                    try {
                        const apiVessels = new ApiVessels(apiClient);
                        const vessels = await apiVessels.fetchVessels();
                
                        // Transform the vessel data so IMO is the key and vessel name is the value
                        const transformedVessels = Object.fromEntries(
                            Object.entries(vessels).map(([name, imo]) => [String(imo), name]) // Ensure IMO remains a string
                        );
                
                        // Update the vesselDictionary state
                        setVesselDictionary(transformedVessels);
                    } catch (error) {
                        console.error("Error fetching vessels:", error);
                    }
                };
                const fetchUsers = async () => {
                    try {
                      const apiUsers = new ApiUsers(apiClient);
                      const usersList = await apiUsers.fetchUsers();
                      setUsers(usersList);
              
                    } catch (error) {
                      console.error('Error loading user data:', error);
                    }
                  };
                fetchContracts();
                fetchVessels();
                fetchUsers();
            } catch (error) {
                console.error('Error loading contracts:', error);
            }
        }
    }, []);

    // Initial load that uses url parameters to set the form and fetch:
    useEffect(() => {
        if (initialLoadUrl.current) {
            initialLoadUrl.current = false;
            let progress = 5; // Reset
            setJobProgress(progress);
            setSelectedContractId(urlContractId !== '' ? urlContractId : 'Select Contract');
            setSelectedWorkflowId(urlWorkflowId);

            // Set user and update params:
            setSelectedUserId(urlUserId !== '' ? urlUserId : currentUserId);
            if(urlUserId === ''){
                updateParams({ user: currentUserId }, []);
            }
            // If job is given, then we should fetch the job and set the job:
            if (urlJobId !== '' && apiClient && showLoadScreen === true) {
                let counter = 0;
                const apiJob = new ApiJob(apiClient, urlJobId, urlUserId);
                
                const fetchJobStatus = async () => {
                    // Prevent further fetches if job is completed
                    if (isJobCompleted.current) return;

                    try {
                        const response = await apiJob.getJobStatus(urlJobId, urlUserId);
                        // Job is completed, fetch it!
                        if (response.data[0].status === 'COMPLETED') {
                            isJobCompleted.current = true; // Mark job as completed
                            clearInterval(intervalId); // Stop fetching when the job is completed

                            const newJob = new JobClass(response.data[0]);
                            setJob(newJob);

                            const contractList: ContractListData[] = await apiContract.fetchContractListFromApi() as ContractListData[];
                            const latestContractVersion = contractList.find((contract) => contract.contractId === newJob.contractMetadata?.contractId)?.createdAt;
                            const newContract = await fetchContract(newJob.contractMetadata?.contractId ?? '', latestContractVersion ?? '') as ContractClass;
                            
                            setSelectedUserId(newJob.userId ?? '');
                            await fetchJobs(newJob.userId ?? '', newJob.contractMetadata?.contractId ?? '');
                            setSelectedWorkflowId(urlWorkflowId ?? '');
                            setWorkflow(newContract?.contractDetails.workflows.find(workflow => workflow.workflowId === urlWorkflowId) ?? null);
                            const workflowExist = await fetchWorkflowResults(urlWorkflowId, newJob, newContract?.createdAt, true);
                            fetchDocumentLinks(newJob.userId ?? '', newJob.jobId ?? '');
                            setShowLoadScreen(false);
                            const chat = new ApiChat(apiClient, urlJobId, newJob.userId ?? '');
                            setApiChat(chat);
                            // Check that workflow need to be updated or created:
                            if (!workflowExist) {
                                setJobStatus("RUNNING AI");
                                setUpdateWorkflowLoadScreen(true);
                                const apiWorkflow = new ApiWorkflow(apiClient);
                                const response = await apiWorkflow.generateWorkflowResponses(newJob.jobId, urlWorkflowId, newJob.userId, newJob, newContract);   
                                setWorkflowResponseList(response);
                                setWorkflowNotificationMessage('');
                                cleanupWorkflowLoadScreen();
                                clearInterval(intervalId);

                                // Fetch jobs again to update the list:
                                fetchJobs(urlUserId,urlContractId,newJob.jobId);
                            }else{
                                updateParams({}, ['loading']);
                                setJobProgress(100);
                            }
                        } else if(response.data[0].status === 'FAILED'){ 
                            // Extract error message
                            const errorInfo = response.data[0].errorInfo;
                            let errorMessage = 'An unknown error occurred. Please contact tech team for help.';
                        
                            if (errorInfo && errorInfo.Cause) {
                                try {
                                    const causeObject = JSON.parse(errorInfo.Cause);
                                    if (causeObject.errorMessage) {
                                        errorMessage = "Please check the documents. They cannot be parsed. They might be locked due to a signature certificate, or the pdf quality is bad. Try use Adobe OCR optimizer before uploading. \n \nSend this to Tech team: \n" + causeObject.errorMessage;
                                    }
                                } catch (parseError) {
                                    errorMessage = 'An error occurred, but details could not be parsed.';
                                }
                            }
                        
                            // Display the error message to the user
                            alert(`NaviLex failed to upload your contract:\n${errorMessage}`);
                        
                            // Cleanup actions
                            clearInterval(intervalId);
                            setShowLoadScreen(false);
                            updateParams({}, ['jobid', 'contract', 'workflow', 'loading']);
                            setSelectedWorkflowId('Select Workflow');
                            setSelectedContractId('Select Contract');
                        }else if(response.data[0].status === 'READY'){ 
                            // Handle loading status
                            setJobStatus("RUNNING AI");
                        }else {
                            // Handle other statuses and progress updates
                            if(response.data[0].status === 'UPLOADED'){
                                setJobStatus("PARSING PDF TO TEXT");
                            }else{
                                setJobStatus(response.data[0].status);
                            }
                        }
                        // Update progress
                        if (progress < 80) {
                            setJobProgress(prevProgress => prevProgress + 5);
                            progress += 5;
                        } else if (progress < 100) {
                            setJobProgress(prevProgress => prevProgress + 1);
                            progress += 1;
                        }
                        // Increase counter and check if it has reached 120 (failsafe)
                        counter++;
                        if (counter === 120) {
                            alert("Seems something went wrong, please contact tech for help.");
                            clearInterval(intervalId);
                            setShowLoadScreen(false);
                            updateParams({}, ['job', 'contract', 'workflow', 'user','loading']);
                            setSelectedWorkflowId('Select Workflow');
                            setSelectedContractId('Select Contract');
                        }
                    } catch (error) {
                        console.error('Error fetching status:', error);
                    }
                };
                fetchJobStatus(); // Immediately fetch the job status when the component mounts

                // Set the interval to fetch the job status every interval
                const intervalId = setInterval(fetchJobStatus, getPollingInterval());

                // Clear the interval on component unmount or when the conditions change
                return () => {
                    clearInterval(intervalId);
                };
            } else {
                // if job is not given, but other parameters are, then fetch the contract and workflows:
                const fetchWorkflows = async () => {
                    if (urlContractId !== '') {
                        const contractList: ContractListData[] = await apiContract.fetchContractListFromApi() as ContractListData[];
                        const latestContractVersion = contractList.find((contract) => contract.contractId === urlContractId)?.createdAt;
                        const newContract = await fetchContract(urlContractId, latestContractVersion ?? '');
                        setSelectedContract(newContract);
                        setWorkflow(newContract?.contractDetails.workflows.find(workflow => workflow.workflowId === urlWorkflowId) ?? null);
                        
                        // If contractId is set, then fetch jobs either with current user or urluser
                        if(urlContractId !== ''){
                            await fetchJobs(urlUserId !== '' ? urlUserId : currentUserId, urlContractId);
                        }
                    }
                }
                fetchWorkflows();
                setShowLoadScreen(false);
            }
        }
    }, []);

    // show loadscreen and update progress
    useEffect(() => {
        if(updateWorkflowLoadScreen){
            if(!timerStarted.current){
                setJobStatus("RUNNING AI");
                timerStarted.current = true;
                setJobProgress(5);
                asyncTimer(90);
            }
        }
    }, [updateWorkflowLoadScreen]);

    // Check for vessel if job changes
    useEffect(() => {
        if(job){
            setVesselRelation(job);
        }
    }, [job]);

    // Handlers:
    const handleSelectContract = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        // Clear everything except user:
        setWorkflowResponseList([]);
        setJob(null);
        setJobList([]);
        setWorkflowNotificationMessage('');
        setSelectedWorkflowId('Select Workflow');
        setDocumentLinks([]);

        // Fetch contract data:
        const contractTypeId = e.target.value;
        // Load data: 
        if (contractTypeId !== 'Select Contract' && apiContract) {
            // find version from contractDefinitionList:
            const contractVersion = contractDefinitionList.find((contract) => contract.contractId === contractTypeId)?.createdAt;
            // Check that contract version is found
            if(contractVersion === undefined){
                console.error('Error when trying to find contract version.');
                return;
            }
            setSelectedContractId(contractTypeId);
            await fetchContract(contractTypeId, contractVersion);
            fetchJobs(selectedUserId,contractTypeId);
            updateParams({ contract: contractTypeId }, ['job', 'workflow']);
        } else {
            setSelectedContract(null);
            setSelectedContractId('Select Contract');
            updateParams({},['job','workflow','contract']);
        }
    };
    const handleSelectUser = (selectedOption: string) => {
        const userId = selectedOption;
        setSelectedUserId(userId);
        setJob(null);
        setJobList([]);
        setDocumentLinks([]);
        setWorkflowResponseList([]);
        setWorkflowNotificationMessage('');

        if (userId !== 'Select User') {
            updateParams({ user: userId }, ['job']);
          if (selectedContractId !== 'Select Contract') {
            // Fetch jobs for the user and contract
            fetchJobs(userId, selectedContractId);
          }
        } else {
          setJobList([]);
        }
    };
    const handleSelectJob = (jobId: string) => {
        setWorkflowNotificationMessage('');
        setDocumentLinks([]);
        setWorkflowResponseList([]);
        
        // find the job from jobList that matches the jobId and set job to selected job:
        const selectedJob = jobList.find((newjob) => newjob.jobId === jobId);

        // Create chat:
        const chat = new ApiChat(apiClient, jobId, selectedJob?.userId ?? '');
        setApiChat(chat);
        setMessages([initialMessage]);
        
        if (selectedJob) {
            setJob(selectedJob);
            fetchDocumentLinks(selectedJob.userId,selectedJob.jobId);
            updateParams({'job':jobId},[]);
            const jobWorkflows = selectedJob.processedWorkflows??[];

            // Check if selected workflow exist in the contract:
            if(selectedWorkflowId != 'Select Workflow' && selectedWorkflowId != null){
                const workflowExists = jobWorkflows.some(workflow => workflow.workflowId === selectedWorkflowId);
                if(workflowExists){
                    fetchWorkflowResults(selectedWorkflowId,selectedJob,selectedContract?.createdAt);
                    setWorkflow(selectedContract?.contractDetails.workflows.find(workflow => workflow.workflowId === selectedWorkflowId)??null);
                }else{
                    // Notify user that they have to run the AI analysis:
                    setWorkflowNotificationMessage("No workflow results found. Click button above to run the AI analysis.");
                }
            }else{
                setWorkflowNotificationMessage('Please select Workflow');
            }
        } else {
            console.error(`Job ${jobId} not found in jobList`);
        }
        
    }
    const handleSelectWorkflow = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedWorkflowId(e.target.value);
        setWorkflowResponseList([]);
        setWorkflowNotificationMessage('');

        if(e.target.value !== 'Select Workflow'){
            // Set workflow and check version:
            setWorkflow(selectedContract?.contractDetails.workflows.find(workflow => workflow.workflowId === e.target.value)??null);
            fetchWorkflowResults(e.target.value,job,selectedContract?.createdAt);
            updateParams({'workflow': e.target.value},[]);
        }else{
            updateParams({},['workflow']);
        }
    }
    const handleChatQuestion = (newPrompt: string,bubbleText:string) => {
        const newMessage = {
            text: bubbleText,
            type: MessageType.Question,
            created: new Date().toISOString()
        };
    
        // Update messages with the new question
        setMessages(prevMessages => [...prevMessages, newMessage]);
    
        // Set waiting state to true before API request
        setIsWaitingForChatResponse(true);

        if (apiChat) {
            apiChat.askQuestion(newPrompt)
                .then((responseMessage) => {
                    setMessages(prevMessages => [...prevMessages, responseMessage]);
                })
                .catch(error => {
                    console.error('Error fetching chat:', error);
                })
                .finally(() => {
                    // Set waiting state to false after the request is completed or fails
                    setIsWaitingForChatResponse(false);
                });
        } else {
            // Immediately set waiting state to false if apiChat is not available
            setIsWaitingForChatResponse(false);
        }
    };
    const handleAnalyzeOrUpdateWorkflow = async () => {
        // Ensure job, jobId, and selectedContract are available
        if (!job?.jobId || !selectedContract) {
            console.error('Error when trying to analyze or update workflow.');
            cleanupWorkflowLoadScreen();
            return;
        }
    
        try {
            setUpdateWorkflowLoadScreen(true);
            const apiWorkflow = new ApiWorkflow(apiClient);
            const response = await apiWorkflow.generateWorkflowResponses(job.jobId, selectedWorkflowId, job.userId, job, selectedContract);
            // Update workflow and prompt list
            const workflow = selectedContract.contractDetails.workflows.find(workflow => workflow.workflowId === selectedWorkflowId) ?? null;
            setWorkflow(workflow);
            setWorkflowResponseList(response);
        
            // Clear workflow notification message
            setWorkflowNotificationMessage('');
            // Fetch jobs again to update the list:
            fetchJobs(selectedUserId,selectedContractId,job.jobId);

        } catch (error) {
            console.error('Error fetching workflow:', error);
        } finally {
            // Cleanup after workflow update
            cleanupWorkflowLoadScreen();
        }
    };
    
    
    // Helper functions:
    async function fetchContract(contractId: string, contractVersion: string): Promise<ContractClass | null> {        
        try {
            if (apiContract) {
                const contractData = await apiContract.fetchContractMetaFromApi(contractId, contractVersion);
    
                // Ensure contractData is an instance of ContractClass
                let contract: ContractClass;
                if (contractData instanceof ContractClass) {
                    contract = contractData;
                } else {
                    contract = new ContractClass(contractData);
                }
    
                // Get name, stored in contract list:
                contract.contractName = contractDefinitionList.find((c) => c.contractId === contractId)?.contractName ?? "NA";
                setSelectedContract(contract);
    
                const newWorkflowOptions = [{ value: 'Select Workflow', label: 'Select Workflow' }];
                if (contract.contractDetails.workflows.length > 0) {
                    contract.contractDetails.workflows.forEach((workflow) => {
                        newWorkflowOptions.push({ value: workflow.workflowId, label: workflow.workflowName });
                    });
                }
                setWorkflowOptions(newWorkflowOptions);
                return contract;
            } else {
                console.error("API contract is not available.");
                return null; // Return null if apiContract is not available
            }
        } catch (error) {
            console.error('Failed to initialize the contract:', error);
            setSelectedContract(null); // Reset or handle errors appropriately
            return null; // Return null in case of error
        }
    }
    async function fetchJobs(userId: string,contractId:string,jobId?:string) {
        try {
            // Fetch jobs available for the user:
            const apiJob = new ApiJob(apiClient);
            const newJobList = await apiJob.fetchAllRecentJobs(userId,contractId);
            // Filter out jobs based on contract type:
            setJobList(newJobList);
            // If job is not set to null, then see if it exist in the list, and if it does, select it:
            if(jobId !== null){
                const selectedJob = newJobList.find((listJob) => listJob.jobId === jobId);
                if(selectedJob){
                    setJob(selectedJob);
                }
            }else{
                if(job !== null){
                    const selectedJob = newJobList.find((listJob) => listJob.jobId === job.jobId);
                    if(selectedJob){
                        setJob(selectedJob);
                    }
                }
            }

        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    }
    function fetchWorkflowResults(
        workflowId: string,
        activeJob: JobClass | null,
        contractCreatedAt: string | null | undefined,
        initialLoad: boolean = false
      ): boolean {
        if (activeJob && contractCreatedAt) {
          try {
            // Load workflow data:
            const apiWorkflow = new ApiWorkflow(apiClient);
            const processedWorkflows = getProcessedWorkflowsById(
              activeJob.processedWorkflows ?? [],
              workflowId
            );

            if (processedWorkflows.length === 0) {
              setWorkflowResponseList([]);
              setWorkflowNotificationMessage(
                "No workflow results found. Click button above to run the AI analysis."
              );
              return false;
            }
      
            // Sort and get the latest processed workflow:
            processedWorkflows.sort((a, b) =>
              a.processedTimestamp > b.processedTimestamp ? 1 : -1
            );
            const lastProcessedWorkflow =
              processedWorkflows[processedWorkflows.length - 1];
            
            // Check version:
            const returnResult = checkWorkflowVersion(
              lastProcessedWorkflow.workflowContractMetadata,
              contractCreatedAt
            );
      
            if (returnResult) {
              apiWorkflow
                .fetchWorkflowResults(lastProcessedWorkflow.sessionId)
                .then((response) => {
                  try {
                    setWorkflowResponseList(response);
                  } catch (e) {
                    setWorkflowResponseList([]);
                    return false;
                  }
                })
                .catch((error) => {
                  setWorkflowResponseList([]);
                  return false;
                });
              return true;
            } else {
              // Initial load - false will trigger the workflow to be updated:
              return returnResult;
            }
          } catch (e) {
            setWorkflowResponseList([]);
            return false; // Added return statement
          }
        }else{
            setWorkflowResponseList([]);
            return false;
        }
    }
    function fetchDocumentLinks(userId: string, jobId: string) {
        apiDocuments.fetchDocument(userId, jobId)
            .then((documentLinks) => {
                // Do something with the document links
                setDocumentLinks(documentLinks);
            })
            .catch((error) => {
                console.error('Error fetching document links:', error);
            });
    }
    function checkWorkflowVersion(workflowContractMeta:ContractMetadata,contractProdVersion:string):boolean{
        // Fetch version from job and contract
        const jobVersion = workflowContractMeta.createdAt;
        if(jobVersion !== contractProdVersion){
            setWorkflowNotificationMessage("The list of questions that the AI sends to the contract has changed since the job was created. You should re-run the AI analysis.");
            return false;
        }
        return true;
    }
    function getProcessedWorkflowsById(processedWorkflows: ProcessedWorkflow[], id: string): ProcessedWorkflow[] {
        return processedWorkflows.filter(workflow => workflow.workflowId === id);
    }
    const cleanupWorkflowLoadScreen = () => {
        setUpdateWorkflowLoadScreen(false);
        timerStarted.current = false;
    };
    async function asyncTimer(seconds: number) {
        let counter = 0;
    
        while (counter < seconds && timerStarted.current) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
    
            if (counter < seconds) {
                // Calculate progress and update
                setJobProgress(prevProgress => {
                    const newProgress = Math.floor(Math.min(100, prevProgress + 100 / seconds));
                    return newProgress;
                });
            }
    
            // Check if system is ready and stop the process if so
            if (!timerStarted.current) {
                break; // Exit loop if the condition is met
            }
            counter++; // Increment counter
        }
    
        // Additional loop in case updateWorkflowLoadScreen remains true
        counter = 0
        while (timerStarted.current && counter <30) {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
            counter++;
        }

        // Give error message, this should not happen:
        if(timerStarted.current){
            alert("Error - process did not end properly")
        }

        // Reset timer:
        timerStarted.current = false;
    }
    // function that takes job as input and then set vesselname to '' if imo is not in relations, or it finds the vesselname from dictionary
    function setVesselRelation(job: JobClass) {
        if (job.contractRelations.hasOwnProperty('imoNo')) {
            const imo = String(job.contractRelations['imoNo']); // Ensure IMO is treated as a string
            if (vesselDictionary[imo]) {
                // Check if vesselDictionary has the IMO as a key and set the vessel name
                setVesselName(vesselDictionary[imo]);
            } else {
                // If IMO is not found, clear the vessel name
                setVesselName('');
            }
        }else{
            setVesselName('');
        }
    }
    const getPollingInterval = (): number => {
        const sizeMB = parseInt(urlDocSize, 10);
        // Default to 1 MB if parsing fails or size is less than 1
        const validSizeMB = isNaN(sizeMB) || sizeMB < 1 ? 1 : sizeMB;
        // Calculate the number of 10 MB blocks, rounding up
        const intervalBlocks = Math.ceil(validSizeMB / 10);
        // Each block adds 5 seconds
        const intervalSeconds = intervalBlocks * 3;
        // Remove size from url parameters and set urlDocSize to default 1:
        setUrlDocSize('1');
        updateParams({}, ['size']);
        // Convert seconds to milliseconds
        return intervalSeconds * 1000;
    };


    return (
        <>
        <PageHeader title="List of Contracts" />
        <PageRow className={`px-4 ${showLoadScreen !== true && updateWorkflowLoadScreen !== true ? 'visually-hidden' : ''}`}>
            <Card 
                columnSize="col-md-12"
                title="Status Ongoing NaviLex Workflow Processing"
                hideCardBody={false}
            >
                <JobStatus status={jobStatus} progress={jobProgress} />
            </Card>
        </PageRow>
        <PageRow className={`px-4 ${showLoadScreen === true || updateWorkflowLoadScreen === true ? 'visually-hidden' : ''}`}>
            <div className="col-md-4">
                <Card 
                    columnSize="col-md-12"
                    title="Select Job"
                    hideCardBody={false}
                >
                    <PageRow>
                        <SelectDropdown
                            id="contract-select"
                            label="Contract Type:"
                            options={contractOptions}
                            value={selectedContractId}
                            onChange={handleSelectContract}
                            columnClass="col-md-5 px-2 pl-md-2 pe-md-0"
                        />
                        {users.length > 0 && (
                            <DropDownSearch
                                id="user-search"
                                label="Select User:"
                                options={users}
                                value={selectedUserId}
                                placeholder="Type to search users..."
                                maxSuggestions={10}
                                onSelect={handleSelectUser}
                                columnClass="col-md-7 px-2"
                            />
                        )}
                    </PageRow>
                    <PageRow>
                            <SelectDropdown 
                                id="workflow-type"
                                label="Select Workflow:"
                                options={workflowOptions}
                                value={selectedWorkflowId??"Select Workflow"}
                                onChange={(e) => handleSelectWorkflow(e)}
                                columnClass="col-md-6 px-2 pl-md-2 pe-md-0"
                                disabled={selectedContractId === 'Select Contract'}
                            />
                            <FormButton
                                label="Start AI Analysis"
                                onClick={() => handleAnalyzeOrUpdateWorkflow()}
                                columnClass="col-md-6 px-2 "
                                // both contractName and selectedContractTye must be filled
                                disabled={selectedWorkflowId === "Select Workflow" || workFlowNotificationMessage === ''}
                            />
                        </PageRow>
                        <PageRow className={workFlowNotificationMessage === '' ? 'visually-hidden' : ''}>
                            <div className="col-md-12 px-2 mb-2">
                                <div className="alert alert-danger" role="alert">
                                    <b>Notice:</b><br/>{workFlowNotificationMessage}
                                </div>
                            </div>
                        </PageRow>
                    <div className={(selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <PageRow className={jobList?.length < 1 ? "visually-hidden" : ""}>
                            <label className="col-md-12 px-2 form-label fw-semibold mt-2">Historical Jobs:</label>
                        </PageRow>
                        <PageRow>
                            <JobList
                                jobs={jobList}
                                onSelectJob={(jobId: string) => handleSelectJob(jobId)}
                                selectedJob={job?.jobId}
                                maxHeight="380px"
                            />
                        </PageRow>
                    </div>
                </Card>
                <Card 
                    columnSize="col-md-12"
                    title="Contract Details"
                >
                    <div className={(!job || selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <PageRow>
                            <TextInput
                                id="contract-name"
                                label="Job Name:"
                                name="contractName"
                                value={job?.jobMetadata?.jobName??'Job Name'}
                                onChange={() => {}}
                                placeholder="Contract Name"
                                columnClass='col-md-8 px-2'
                                disabled={true}
                            />
                            <TextInput
                                id="job-date"
                                label="Created:"
                                name="jobDate"
                                // Only use the yyyy-mm-dd from timestamp
                                value={job?.createdOrUpdatedTimestamp?.split('T')[0]??'Date'}
                                onChange={() => {}}
                                placeholder="Date"
                                columnClass='col-md-4 px-2'
                                disabled={true}
                            />
                        </PageRow>
                        <PageRow className={vesselName ? '' : 'visually-hidden'}>
                            <TextInput
                                id="vessel-name"
                                label="Vessel Name:"
                                name="vesselName"
                                value={vesselName}
                                onChange={() => {}}
                                placeholder="Vessel Name"
                                columnClass="col-md-12 px-2"
                                disabled={true}
                            />
                        </PageRow>
                        <PageRow>
                            <TextArea
                                id="job-comments"
                                label="Comments from initiator:"
                                name="jobComments"
                                value={job?.jobMetadata?.jobComments??'Job Comments'}
                                onChange={() => {}}
                                placeholder="Comments"
                                rows={3}
                                scrollable={true}
                                columnClass='col-md-12 px-2'
                                disabled={true}
                            />
                        </PageRow>
                        <PageRow>
                            <DocumentLinks
                                label="Document Links:"
                                links={documentLinks}
                            />
                        </PageRow>
                        
                    </div>
                </Card>
                <Card 
                    columnSize="col-md-12"
                    title="Chat"
                    enableExpand={true}
                >
                    <div className={(!job || selectedUserId === 'Select User') ? 'visually-hidden' : undefined}>
                        <Chat messages={messages} onMessageSubmit={handleChatQuestion} maxHeight='450px' isLoading={isWaitingForChatResponse}/>
                    </div>
                </Card>
            </div>
            <Card 
                columnSize="col-md-8"
                title="Findings & Advice"
                >
                    <WorkflowPrompts workflowResponses={workflowResponseList} workflow={selectedWorkflow} />
            </Card>
        </PageRow>
        </>
    );
}

export default DefaultContent;