// src/services/configService.ts
interface Config {
    name:string;
    apiEndpoint: string;
    redirect_url: string;
    main_page: string;
    [key: string]: string;
  }

  const prodConfig: Config = {
    name:"prod",
    apiEndpoint: 'https://api.prod.westernbulk.cloud/v1',
    redirect_url: 'https://auth.prod.westernbulk.cloud/oauth2/authorize?identity_provider=LOGIN&response_type=token&client_id=52q24rpchcoct0fkv2cktqi4ie&redirect_uri=https://prod.navi-lex.com&scope=aws.cognito.signin.user.admin%20email%20openid',
    main_page:"https://data.prod.westernbulk.cloud/",
    // Add other prod-specific settings here
  };
  
  const testConfig: Config = {
    name:"test",
    apiEndpoint: 'https://api.sandbox.westernbulk.cloud/v1',
    redirect_url: 'https://auth.sandbox.westernbulk.cloud/oauth2/authorize?identity_provider=LOGIN&response_type=token&client_id=13b613ntlkph5s8mncslosvlot&redirect_uri=https://sandbox.navi-lex.com&scope=aws.cognito.signin.user.admin%20email%20openid',
    main_page:"https://data.sandbox.westernbulk.cloud/",
    // Add other test-specific settings here
  };
  
  const localConfig: Config = {
    name:"local",
    apiEndpoint: 'https://api.sandbox.westernbulk.cloud/v1',
    redirect_url: 'https://auth.sandbox.westernbulk.cloud/oauth2/authorize?identity_provider=LOGIN&response_type=token&client_id=13b613ntlkph5s8mncslosvlot&redirect_uri=https://data.sandbox.westernbulk.cloud&scope=aws.cognito.signin.user.admin%20email%20openid',
    main_page:"https://data.sandbox.westernbulk.cloud/",
    // Add other local-specific settings here
  };
  
  const getEnvironmentConfig = (): Config => {
    const hostname = window.location.hostname;
  
    if (hostname.includes('localhost')) {
      return localConfig;
    } else if (hostname.includes('sandbox')) {
      return testConfig;
    } else if(hostname.includes('prod')){
      return prodConfig;
    } else {
      return testConfig;
    }
  };
  
  export const getConfig = getEnvironmentConfig;