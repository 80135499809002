import { ApiClient } from './apiClient';
import { ContractClass } from '../models/ContractClass';

interface FetchResponseContractList {
    data:[ContractListData]
}
export interface ContractListData{
    createdAt:string;
    contractName:string;
    contractId:string;
}

export class ApiContract {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    async fetchContractListFromApi(): Promise<any> {
        try {
            const response = await this.apiClient.post<FetchResponseContractList>('genai/fetchData?dataType=contractList','{}' );
            return response.data;
        } catch (error) {
            throw new Error('Failed to fetch contract');
        }
    }

    async fetchContractMetaFromApi(contractId: string, createdAt:string): Promise<any> {
        try {
            const payload = JSON.stringify({ contractId, createdAt });
            const response = await this.apiClient.post<any>('genai/fetchData?dataType=contractMetadata', payload);
            if(response.data){
                const contract = new ContractClass(response.data[0]);
                return contract;
            }else{
                return null;
            }
        } catch (error) {
            throw new Error('Failed to fetch contract meta');
        }
    }

    async updateContractMeta(contract: ContractClass): Promise<any> {
        try {
            const payload = JSON.stringify(contract);
            const response = await this.apiClient.post<any>('genai/storeData?dataType=contractMetadata', payload);
            return response;
        } catch (error) {
            throw new Error('Failed to update contract meta');
        }
    }

    async updateContractList(contractName:string,contractId:string,createdAt:string): Promise<any> {
        try {
            const payload = JSON.stringify({ contractName,contractId,createdAt });
            const response = await this.apiClient.post<any>('genai/storeData?dataType=updateContract', payload);
            return response;
        } catch (error) {
            throw new Error('Failed to update contract list');
        }
    }
   
}