// src/components/MainContent/MainContent.tsx
import React, { useEffect } from 'react';
import { usePage } from "../../contexts/PageContext";
import { useMenu } from "../../contexts/MenuContext";
import styles from './MainContent.module.scss';
import Menu from "../navigation/Menu/Menu";

// pages
import DefaultContent from '../../pages/defaultContent/DefaultContent';
//import CharterAdmin from '../../pages/contractAdmin/ContractAdmin';
import NewContract from '../../pages/uploadContract/UploadContract';
import Workflow from '../../pages/workflow/Workflow';
import Token from '../../pages/token/Token';

const MainContent: React.FC = () => {
  const { page } = usePage();
  const { isVisible } = useMenu();
  const { setIsVisible } = useMenu(); 

  useEffect(() => {
    // Hide the menu for the 'navilex-admin-contract' page, show for others
    setIsVisible(page === 'defaultContent');
  }, [page, setIsVisible]);

  const renderContent = () => {
    switch(page) {
      //case 'contract-admin':
      //  return <CharterAdmin />;
      case 'workflows':
        return <Workflow />;
      case 'new-contract':
        return <NewContract />;
      case 'token':
        return <Token />;
      // Add more cases as needed
      default:
        return <DefaultContent />;
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className={`${styles.mainWindow} row p-0 m-0`} id="main-window">
        <div className="m-0 p-0" id="alert_message_box">
          {/* Alert messages will be displayed here */}
        </div>

        <div 
          id="vertical_menu"
          className="col-lg-2 p-2 m-0 collapse navbar-collapse"
          style={{ maxWidth: '170px', display: isVisible ? 'block' : 'none' }}>
          <Menu/>
        </div>


        <div className={`${styles.mainContent} col p-2 m-0`} id="main_content">
          {/* Main content goes here */}
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
