import React, { useState } from 'react';

interface TextInputProps {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  type?: 'text' | 'email' | 'password';
  placeholder?: string;
  columnClass?: string; // Allows for layout customization
  name?: string;
  disabled?: boolean; // Add a disabled prop
  autoComplete?: 'on' | 'off';
  inputClass?: string; // Allows for input customization
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  value,
  onChange,
  type = 'text', // Default input type
  placeholder = '',
  columnClass = 'col-md-2', // Default column size, matching SelectDropdown
  name,
  disabled = false, // Default to enabled
  autoComplete = 'off', // Default to 'off' to disable autofill
  inputClass = '',
}) => {
  
  const [newValue, setValue] = useState(value);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e);
  };

  return (
    <div className={`${columnClass} mb-2`}>
      <label htmlFor={id} className="form-label fw-semibold">{label}</label>
      <input
        type={type}
        id={id}
        className={`form-control form-control-sm ${inputClass}`}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default TextInput;
