import { ApiClient } from './apiClient';

interface FetchResponseUsersList {
  data: Array<string>;
}

export class ApiUsers {
    private apiClient: ApiClient;

    constructor(apiClient: ApiClient) {
        this.apiClient = apiClient;
    }

    async fetchUsers(): Promise<Array<string>> {
      try {

        const payload = JSON.stringify({});
        const response = await this.apiClient.post<FetchResponseUsersList>(
          'genai/fetchData?dataType=usersList',
          payload
        );
    
        const usersList = response.data; // Extract the array from the data property
    
        // Sort the list of users
        const sortedUsers = usersList.sort();
        return sortedUsers;
      } catch (error) {
        console.error('Error fetching users:', error);
        throw new Error('Failed to fetch users list');
      }
    }
}