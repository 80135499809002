import { jsPDF } from "jspdf";

export function createPdfBufferFromText(text: string): Promise<Uint8Array> {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const margin = 10; // Margin from top and bottom in points
    const lineHeight = 10; // Line height in points, adjusted to font size
    const fontSize = 10; // Set font size

    doc.setFontSize(fontSize); // Set the font size

    // Explicitly type the lines array
    const lines: string[] = doc.splitTextToSize(text, doc.internal.pageSize.width - margin * 2);

    let currentPage = 0;

    lines.forEach((line, index) => {
        if (index % Math.floor((pageHeight - margin * 2) / lineHeight) === 0 && index > 0) {
            doc.addPage(); // Add a new page after reaching the max lines per page
            currentPage++;
        }
        const yPosition = margin + ((index % Math.floor((pageHeight - margin * 2) / lineHeight)) + 1) * lineHeight;
        doc.text(line, margin, yPosition); // Print text at calculated position
    });

    const arrayBuffer = doc.output("arraybuffer"); // Get PDF as ArrayBuffer

    return Promise.resolve(new Uint8Array(arrayBuffer));
}